import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  MutationFunction,
} from '@tanstack/react-query';

import { useErrorBoundaryContext } from 'src/modules/error/ErrorBoundaryProvider';
import { ApiError } from '../types';

type UseMutateHookMutationFunction<T, Y> = MutationFunction<T, Y>;
type MutationOptions<T, Y> = UseMutationOptions<T, ApiError, Y> & {
  disableErrorHandling?: boolean;
};

type UseMutate = <T, Y>(
  fn: UseMutateHookMutationFunction<T, Y>,
  options?: MutationOptions<T, Y>
) => UseMutationResult<T, ApiError, Y>;

const useMutate: UseMutate = (fn, options?) => {
  const { onMutationError } = useErrorBoundaryContext();

  return useMutation(fn, {
    onError: options?.disableErrorHandling
      ? undefined
      : error => {
          onMutationError(error);
        },
    ...options,
  });
};

export default useMutate;
