import * as React from 'react';
import { useQuery } from '@tanstack/react-query';

import { useErrorBoundaryContext } from 'src/modules/error/ErrorBoundaryProvider';
import hasNull from 'src/utils/hasNull';
import { QueryObject, UseGetOptions, UseGetResult } from '../types';

const DEFAULT_OPTIONS = {
  enabled: true,
};

type UseGet = <T, U = T>(
  qo: QueryObject<T>,
  options?: UseGetOptions<T, U>
) => UseGetResult<T, U>;

const useGet: UseGet = (
  { queryKey, queryFn, optionalVariables },
  options = DEFAULT_OPTIONS
) => {
  options = {
    enabled: true,
    ...options,
  };
  const { onQueryError } = useErrorBoundaryContext();
  const enabled = !hasNull(queryKey, optionalVariables) && options.enabled;

  const result = useQuery(queryKey, queryFn, {
    onError: error =>
      options.disableErrorHandling
        ? undefined
        : onQueryError(result.refetch, error),
    ...options,
    enabled,
  });

  React.useEffect(() => {
    if (options.onData && result.data && enabled) {
      options.onData(result.data);
    }
  }, [result.data]);

  return result;
};

export default useGet;
