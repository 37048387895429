import * as React from 'react';
import { useGetFreeDeliveryProd } from '../api/queries';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { ApiCart } from '../api/types';

const useCheckForFreeDelivery = (cartDetails?: ApiCart) => {
  const cart = useSelector((state: RootState) => state.products);

  const { userLocation } = useLocationContext();
  const { isAuthed } = useAuthContext();
  const { data: productIds } = useGetFreeDeliveryProd({
    countryId: userLocation?.country,
  });

  const cartData =
    isAuthed && cartDetails ? cartDetails?.cartDetails?.cartLines : cart;

  const validForFreeDelivery = React.useMemo(() => {
    const hasFreeDeliveryProd = cartData.some(item =>
      productIds?.includes(item?.id)
    );

    return hasFreeDeliveryProd;
  }, [cartData, productIds]);

  return validForFreeDelivery;
};

export default useCheckForFreeDelivery;
