import { ShoppingBagIcon } from '@heroicons/react/outline';
import { ShoppingBagIcon as ShoppingBagSolid } from '@heroicons/react/solid';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import * as React from 'react';
import { useSelector } from 'react-redux';
import useNavigator from 'src/hooks/useNavigator';
import { useCartContext } from 'src/modules/app/providers/CartProvider';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { RootState } from 'src/redux/store';

interface CartButtonProps {
  hide: boolean;
  active: boolean;
  light?: boolean;
}

const CartButton: React.FC<CartButtonProps> = ({ hide, active, light }) => {
  const cart = useSelector((state: RootState) => state.products);
  const navigate = useNavigator();
  const { cartDetails } = useCartContext();
  const { isAuthed } = useAuthContext();

  const isDraft = cartDetails?.status === 'Draft';
  const cartCount = cart.reduce((a, b) => a + b?.amount, 0);
  const authCartCount = cartDetails?.cartDetails?.cartLines?.reduce(
    (a, b) => a + b?.quantity,
    0
  );
  const quantity =
    isAuthed && isDraft && authCartCount ? authCartCount : cartCount;
  return (
    <div
      className={`relative w-full pt-2 ${hide && 'hidden'} z-10`}
      data-cy="cy-cart-btn"
    >
      <Button
        leftIcon={active ? ShoppingBagSolid : ShoppingBagIcon}
        iconProps={{ size: 25 }}
        className={`${
          active && 'text-primary-500'
        } m-0 w-full cursor-pointer p-0 ${
          light ? 'text-white' : 'text-secondary-500'
        } `}
        onClick={() => {
          navigate('/cart');
        }}
        variant="text"
      />
      <div
        onClick={() => {
          navigate('/cart');
        }}
        className=" absolute -top-3 -left-4 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-primary-500 text-white"
      >
        <p className="ltr:translate-y-[1px]">{quantity}</p>
      </div>
    </div>
  );
};

export default CartButton;
