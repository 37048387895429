// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported as isMessagingSupported,
} from 'firebase/messaging';
import { isBrowser } from 'src/utils';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID,
};

export const publicKey = process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY;
export const isPushNotificationsSupported =
  isBrowser() && isMessagingSupported();

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = isSupported().then(yes => (yes ? getAnalytics(app) : null));
const messaging = isMessagingSupported().then(yes =>
  yes ? getMessaging(app) : null
);

export const getMessagingToken = async () => {
  let currentToken = '';

  try {
    currentToken = await getToken(await messaging, { vapidKey: publicKey });
  } catch (error) {
    console.info('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

export const onMessageListener = async () =>
  new Promise(resolve =>
    (async () => {
      const messagingResolve = await messaging;
      if (messagingResolve) {
        onMessage(messagingResolve, payload => {
          resolve(payload);
        });
      }
    })()
  );
export { analytics, app };
