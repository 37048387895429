import { ApiError } from 'src/api/types';

const apiErrorMessage = (error: ApiError): string => {
  const responseData = error?.response?.data;

  let message = '';

  if (typeof responseData === 'object') {
    const messageObj = Object.values(responseData)?.[0];
    message =
      typeof messageObj === 'object'
        ? Object.values(messageObj)?.[0]
        : messageObj;
  }

  return message;
};

export default apiErrorMessage;
