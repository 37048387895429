import Button from '@SnackatCafe/snackat-ui/dist/Button';
import { BellIcon } from '@heroicons/react/outline';
import { BellIcon as BellIconSolid } from '@heroicons/react/solid';
import * as React from 'react';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { useNotificationsContext } from 'src/modules/notifications/providers/NotificationsProvider';

interface NotificationButtonProps {
  toggleNotifications: () => void;
  hide: boolean;
  active: boolean;
  light?: boolean;
}

export const NotificationButton: React.FC<NotificationButtonProps> = ({
  toggleNotifications,
  hide,
  active,
  light,
}) => {
  const { unReadCount } = useNotificationsContext();
  const { isAuthed } = useAuthContext();
  return (
    <div className={`${hide ? 'hidden' : 'relative'}`}>
      {isAuthed && unReadCount !== 0 && (
        <div className="text-xxs pointer-events-none absolute -top-3 -left-4 z-10 flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-white">
          {unReadCount}
        </div>
      )}
      <Button
        leftIcon={active ? BellIconSolid : BellIcon}
        iconProps={{ size: 25 }}
        className={`mt-2 p-0 ${
          active
            ? 'text-primary-500'
            : light
            ? 'text-white'
            : 'text-secondary-500'
        } `}
        variant="text"
        onClick={toggleNotifications}
      />
    </div>
  );
};
