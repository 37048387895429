export const NOT_REGISTERED = 'This User it Not Registered!';
const EXPIRED_SUBSCRIPTION = 'Please Renew Your Subscription!';
const NOT_ADMIN = 'Must Be Supplier Admin!';
const NOT_ACTIVE = 'Must Be an Active User!';
const CALL_SUPPORT = 'Please Call the Support, To access the Dashboard!';
const OTP_INVALID = 'This OTP is invalid';
const SIGNUP_USER = 'Must Be Supplier Admin, Not Application Owner!';
const PHONE_INVALID = 'Enter a valid phone number.';
const ALREADY_A_SUPPLIER =
  'User with this Phone Number is already in our system, call support!';
const PHONE_IN_USE =
  'Application with this Business Phone Number already exists.';
const EMAIL_IN_USE_APPLICATION =
  'Application with this Email Address already exists.';
const EMAIL_IN_USE_SUPPLIER =
  'User with this Email is already in our system, call support!';
const PHONE_INVALID_SIGNUP = 'The phone number entered is not valid.';
export const USERNAME_ALREADY_EXISTS =
  'User with this username already exists!';
const USER_CREATED = 'User created successfully!';
export const ADDRESS_ERROR = 'Customer Address is not set for Order';

export const API_VALIDATION_ERRORS = {
  [PHONE_INVALID]: 'phoneInvalid',
  [NOT_REGISTERED]: 'notRegistered',
  [EXPIRED_SUBSCRIPTION]: 'expiredSubscription',
  [NOT_ADMIN]: 'notAdmin',
  [NOT_ACTIVE]: 'notActive',
  [CALL_SUPPORT]: 'callSupport',
  [OTP_INVALID]: 'otpInvalid',
  [SIGNUP_USER]: 'signupUser',
  [ALREADY_A_SUPPLIER]: 'alreadyASupplier',
  [PHONE_IN_USE]: 'phoneInUse',
  [EMAIL_IN_USE_APPLICATION]: 'emailInUse',
  [EMAIL_IN_USE_SUPPLIER]: 'emailInUse',
  [PHONE_INVALID_SIGNUP]: 'phoneInvalid',
  [USERNAME_ALREADY_EXISTS]: 'phoneAlreadyUsed',
  [USER_CREATED]: 'userAdded',
};
