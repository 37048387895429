import { InputOption } from '@SnackatCafe/snackat-ui';

import { getClient } from 'src/api/restClient';
import { UseGetHook, UseInfiniteGetHook } from 'src/api/types';
import useGet from 'src/api/useGet';
import useInfiniteGet from 'src/api/useInfiniteGet';
import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';

import {
  ApiArea,
  APiCitiesList,
  ApiCountry,
  ApiGeoData,
  ApiServiceStatus,
} from './types';

export enum addressQueryKeyEnum {
  cities = 'cities-data',
  areas = 'areas-data',
  geoData = 'geo-data',
  serviceStatus = 'service-status',
  countries = 'countries',
}
interface GetCitiesVariables {
  term?: string;
  countryId: number;
  options?: any;
  language?: 'en' | 'ar';
}
export const useGetCities: UseGetHook<
  InputOption<string>[],
  GetCitiesVariables
> = (vars, options) => {
  const { language } = useTranslationContext();
  return useGet(
    {
      queryKey: [addressQueryKeyEnum.cities, vars],
      queryFn: async () => {
        const data: APiCitiesList = await getClient(
          `/odoo/states/?country_id=${vars.countryId}`
        );
        const values: InputOption<string>[] = data.data.map(city => ({
          label: language === 'en' ? city.name : city.arName,
          value: city.id.toString(),
          disabled: city.disable,
        }));
        return values;
      },
    },
    options
  );
};

interface GetAreasVariables {
  term?: string;
  perPage: number;
  pageNumber?: string;
  lang: 'ar_001' | 'en_US';
  stateId: number;
}

export const useGetAreas: UseInfiniteGetHook<ApiArea[], GetAreasVariables> = (
  vars,
  options
) => {
  return useInfiniteGet(
    {
      queryKey: [addressQueryKeyEnum.areas, vars],
      queryFn: async ({ pageParam = 1 }) => {
        const data: any = await getClient(
          `/odoo/areas/?state_id=${vars.stateId}&per_page=${vars.perPage}&lang=${vars.lang}&page_number=${pageParam}&term=${vars.term}`
        );
        return data;
      },
      optionalVariables: ['term'],
    },
    options
  );
};

interface GetGeoDataVariables {
  countryId: number;
}

export const useGetGeoData: UseGetHook<any, GetGeoDataVariables> = (
  vars,
  options
) => {
  return useGet(
    {
      queryKey: [addressQueryKeyEnum.geoData, vars],
      queryFn: async () => {
        const data: ApiGeoData = await getClient(
          `/core/geo-data/?country_external_id=${vars.countryId}`
        );
        return data.countryGeoData;
      },
    },
    options
  );
};

interface GetServiceStatusVariables {
  countryId: number;
}

export const useGetServiceStatus: UseGetHook<
  ApiServiceStatus,
  GetServiceStatusVariables
> = (vars, options) => {
  return useGet(
    {
      queryKey: [addressQueryKeyEnum.serviceStatus, vars],
      queryFn: async () => {
        const data: ApiServiceStatus = await getClient(
          `/odoo/service-status/?country_id=${vars.countryId}`
        );
        return data;
      },
    },
    options
  );
};

export const useGetCountries: UseGetHook<ApiCountry[], unknown> = (
  _,
  options
) => {
  return useGet(
    {
      queryKey: [addressQueryKeyEnum.countries],
      queryFn: async () => {
        const data: any = await getClient(`/odoo/countries/`);
        return data.data;
      },
    },
    options
  );
};
