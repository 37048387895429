import React from 'react';
import Lottie from 'react-lottie';

interface LottieWrapperProps {
  animationData: any;
  width?: number;
  height?: number;
  loop?: boolean;
  autoplay?: boolean;
}

const LottieWrapper: React.FC<LottieWrapperProps> = ({
  animationData,
  width,
  height,
  loop = true,
  autoplay = true,
}) => {
  const defaultOptions = {
    loop,
    autoplay,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      width={width ? width : 250}
      height={height ? height : 250}
    />
  );
};

export default LottieWrapper;
