import { useMedia } from 'react-use';

import { mobileMaxSize } from 'src/styles';

const useMobile = (): boolean => {
  const isMobile = useMedia(`(max-width:${mobileMaxSize})`);

  return isMobile;
};

export default useMobile;
