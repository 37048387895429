import * as React from 'react';
import useNavigator from 'src/hooks/useNavigator';

interface LogoProps {
  className?: string;
  size?: number;
  goesToLanding?: boolean;
  icon?: boolean;
}

const LogoIcon: React.FC<LogoProps> = props => {
  const {
    className = '',
    size = 100,
    goesToLanding = true,
    icon = false,
  } = props;

  const navigate = useNavigator();

  const content = (
    <img
      className="h-full w-full object-cover"
      src={!icon ? '/images/logo-small.webp' : '/images/logoIcon.webp'}
      alt="Snackat Cafe"
    />
  );

  return goesToLanding ? (
    <div
      onClick={() => {
        navigate('/home');
      }}
      data-cy="cy-logo-icon"
    >
      <div
        className={`cursor-pointer overflow-hidden ${className}`}
        style={{ width: size, height: !icon ? size / 1.6 : size * 1.2 }}
      >
        {content}
      </div>
    </div>
  ) : (
    <div
      className={`overflow-hidden ${className}`}
      style={{ width: size, height: !icon ? size / 1.6 : size * 1.2 }}
    >
      {content}
    </div>
  );
};

export default LogoIcon;
