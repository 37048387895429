import { getClient } from 'src/api/restClient';
import { UseGetHook, UseInfiniteGetHook } from 'src/api/types';
import useGet from 'src/api/useGet/useGet';
import {
  ApiDevicesData,
  ApiNotification,
  ApiNotificationsCount,
} from './types';
import { NOTIFICATIONS_INVALIDATES_AFTER } from '../constants';
import useInfiniteGet from 'src/api/useInfiniteGet/useInfiniteGet';

export enum NotificationsQueryKeysEnum {
  Devices = 'devices',
  Count = 'notifications-count',
  Notifications = 'notifications',
}

export const useGetDevices: UseGetHook<ApiDevicesData> = (_, options) => {
  return useGet(
    {
      queryKey: [NotificationsQueryKeysEnum.Devices],
      queryFn: () => getClient<ApiDevicesData>('/users/devices/'),
    },
    options
  );
};

export const useGetNotificationsCount: UseGetHook<ApiNotificationsCount> = (
  _,
  options
) => {
  return useGet(
    {
      queryKey: [NotificationsQueryKeysEnum.Count],
      queryFn: () =>
        getClient<ApiNotificationsCount>('/notifications/unread-counts/'),
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: NOTIFICATIONS_INVALIDATES_AFTER,
      ...options,
    }
  );
};

export const useGetNotifications: UseInfiniteGetHook<ApiNotification[]> = (
  _,
  options
) => {
  return useInfiniteGet(
    {
      queryKey: [NotificationsQueryKeysEnum.Notifications],
      queryFn: async ({ pageParam = 1 }) => {
        const data: ApiNotification[] = await getClient(
          `/notifications/?page=${pageParam}`
        );
        return data;
      },
    },
    {
      refetchOnMount: true,
      refetchInterval: NOTIFICATIONS_INVALIDATES_AFTER,
      ...options,
    }
  );
};
