import { InputOption } from '@SnackatCafe/snackat-ui';

import { UseGetHook } from 'src/api/types';
import {
  ApiLoyaltyCitiesData,
  ApiLoyaltyPointsProduct,
  ApiLoyaltyPointsProductsData,
  ApiUserLoyaltyPoints,
} from './types';
import useGet from 'src/api/useGet/useGet';
import { getClient, optionsClient } from 'src/api/restClient';

export enum LoyaltyPointsQueryKeysEnum {
  userLoyaltyPoints = 'user-points',
  loyaltyPointsCities = 'loyalty-cities',
  loyaltyProducts = 'loyalty-products',
  loyaltyProductDetails = 'loyalty-product-details',
}

export const useGetUserLoyaltyPoints: UseGetHook<
  ApiUserLoyaltyPoints,
  unknown
> = (_, options) => {
  return useGet(
    {
      queryKey: [LoyaltyPointsQueryKeysEnum.userLoyaltyPoints],
      queryFn: async () => {
        const data: ApiUserLoyaltyPoints = await getClient(
          `/users/loyalty-points/`
        );
        return data;
      },
    },
    options
  );
};

interface GetLoyaltyPointsCitiesVariables {
  countryId: number;
  language: 'ar' | 'en';
}

export const useGetLoyaltyPointsCities: UseGetHook<
  InputOption<string>[],
  GetLoyaltyPointsCitiesVariables
> = (vars, options) => {
  return useGet(
    {
      queryKey: [LoyaltyPointsQueryKeysEnum.loyaltyPointsCities, vars],
      queryFn: async () => {
        const data: ApiLoyaltyCitiesData = await optionsClient(
          `/odoo/reward/?country_id=${vars?.countryId}`
        );

        const values: InputOption<string>[] = data.data.map(city => ({
          label: vars?.language === 'en' ? city.name : city.arName,
          value: vars?.language === 'en' ? city.name : city.arName,
        }));
        return values;
      },
    },

    options
  );
};

interface GetLoyaltyPointsProductsVariables {
  city: string;
  lang: 'ar_001' | 'en_US';
}

export const useGetLoyaltyPointsProducts: UseGetHook<
  ApiLoyaltyPointsProduct[],
  GetLoyaltyPointsProductsVariables
> = (vars, options) => {
  return useGet(
    {
      queryKey: [LoyaltyPointsQueryKeysEnum.loyaltyProducts, vars],
      queryFn: async () => {
        const data: ApiLoyaltyPointsProductsData = await getClient(
          `/odoo/reward/?lang=${vars?.lang}&city=${vars?.city}`
        );
        return data.data;
      },
    },
    options
  );
};

interface GetLoyaltyPointsProductDetailsVariables {
  lang: 'ar_001' | 'en_US';
  city: string;
  lineId: number;
}

export const useGetLoyaltyProductDetails: UseGetHook<
  ApiLoyaltyPointsProduct,
  GetLoyaltyPointsProductDetailsVariables
> = (vars, options) => {
  return useGet(
    {
      queryKey: [LoyaltyPointsQueryKeysEnum.loyaltyProductDetails, vars],
      queryFn: async () => {
        const data: ApiLoyaltyPointsProduct = await getClient(
          `/odoo/reward/detail/?lang=${vars?.lang}&city=${vars?.city}&line_id=${vars?.lineId}`
        );
        return data;
      },
    },
    options
  );
};
