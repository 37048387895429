/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import Head from 'next/head';
import NextApp from 'next/app';
import 'tailwindcss/tailwind.css';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { UserConfig, appWithTranslation } from 'next-i18next';
import { NextPage } from 'next';
import { QueryParamProvider } from 'use-query-params';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Cookies from 'js-cookie';
import TimeAgo from 'javascript-time-ago';
import { GoogleTagManager } from '@next/third-parties/google';
import nextI18NextConfig from '../../next-i18next.config';

import en from 'javascript-time-ago/locale/en.json';
import ar from 'javascript-time-ago/locale/ar.json';

// the order of css imports here matters
import '@SnackatCafe/snackat-ui/dist/index.css';
import 'src/styles/index.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import ErrorBoundaryProvider from 'src/modules/error/ErrorBoundaryProvider';
import TranslationProvider from 'src/modules/app/providers/TranslationProvider';
import RootLayout from 'src/modules/app/components/RootLayout';
import AppStateProvider from 'src/modules/app/providers/AppStateProvider';
import SearchProvider from 'src/modules/app/providers/SearchProvider';
import UserProvider from 'src/modules/auth/providers/UserProvider';
import AuthProvider from 'src/modules/auth/providers/AuthProvider';
import FilterProvider from 'src/modules/app/providers/FilterProvider';
import CartProvider from 'src/modules/app/providers/CartProvider';

import { store } from '../redux/store';
import LocationProvider from 'src/modules/app/providers/LocationProvider';
import TrackingProvider from 'src/modules/app/providers/TrackingProvider';
import { useRouter } from 'next/router';

const STAGE = process.env.NEXT_PUBLIC_APP_STAGE;
const persistor = persistStore(store);

TimeAgo.addDefaultLocale(ar);
TimeAgo.addLocale(en);

const PersistGateEnable: React.FC = ({ children }) => {
  const isAuthed = !!Cookies.get('accessToken');

  if (!isAuthed) {
    return (
      <PersistGate persistor={persistor} loading={null}>
        {children}
      </PersistGate>
    );
  }
  return <>{children}</>;
};

export function reportWebVitals(metric: string): void {
  STAGE === 'development' &&
    process.env.TRACK_PERFORMANCE &&
    console.info(metric);
}

interface AppProps {
  Component: React.FC;
  pageProps: Record<string, any>;
  router;
}

const App: NextPage<AppProps> = props => {
  const { Component, pageProps } = props;
  const router = useRouter();
  const production = process.env.NEXT_PUBLIC_APP_STAGE === 'production';
  const [mounted, setMounted] = React.useState<boolean>(false);

  const [queryClient] = React.useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 3,
          staleTime: 1 * 60 * 1000, // 1 minute
        },
      },
    })
  );

  React.useEffect(() => setMounted(true), []);

  if (!mounted || !router.isReady) return null;

  return (
    <>
      <Head>
        <link
          rel="stylesheet preload prefetch"
          href="/fonts/linasoft/bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet preload prefetch"
          href="/fonts/linasoft/regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet preload prefetch"
          href="/fonts/league-spartan/static/LeagueSpartan-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet preload prefetch"
          href="/fonts/Sombra/Sombra-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet preload prefetch"
          href="/fonts/Sombra/Sombra-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet preload prefetch"
          href="/fonts/Ping/Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet preload prefetch"
          href="/fonts/Ping/Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.webp" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      {production && <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM} />}

      <QueryClientProvider client={queryClient}>
        <Hydrate state={props.pageProps.dehydratedState}>
          <ReactQueryDevtools initialIsOpen={false} />
          <QueryParamProvider>
            <TranslationProvider>
              <AppStateProvider>
                <FilterProvider>
                  <UserProvider>
                    <ErrorBoundaryProvider>
                      <AuthProvider>
                        <LocationProvider>
                          <SearchProvider>
                            <TrackingProvider>
                              <Provider store={store}>
                                <PersistGateEnable>
                                  <CartProvider>
                                    <RootLayout>
                                      <Component {...pageProps} />
                                    </RootLayout>
                                  </CartProvider>
                                </PersistGateEnable>
                              </Provider>
                            </TrackingProvider>
                          </SearchProvider>
                        </LocationProvider>
                      </AuthProvider>
                    </ErrorBoundaryProvider>
                  </UserProvider>
                </FilterProvider>
              </AppStateProvider>
            </TranslationProvider>
          </QueryParamProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

(App as any).getStaticProps = async appContext => ({
  ...(await (NextApp as any).getStaticProps(appContext)),
});

export default appWithTranslation(App, nextI18NextConfig as any as UserConfig);
