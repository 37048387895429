import { useMedia } from 'react-use';

import { tabletMaxSize } from 'src/styles';

const useTablet = (): boolean => {
  const isTablet = useMedia(`(max-width:${tabletMaxSize})`);

  return isTablet;
};

export default useTablet;
