const isEnabled = process.env.NEXT_PUBLIC_APP_STAGE === 'production';

export const pageview = (url: string): void => {
  if (isEnabled) {
    (window as any)?.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

export const event = ({
  action,
  params,
}: {
  action: string;
  params: Record<string, any>;
}): void => {
  if (isEnabled) {
    (window as any)?.gtag('event', action, params);
  }
};
