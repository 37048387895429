import isObject from './isObject';

const hasNull = (obj: Record<string, any>, ignore = []): boolean => {
  return Object.keys(obj).some(key => {
    if (isObject(obj[key])) {
      return hasNull(obj[key], ignore);
    } else {
      return ignore.includes(key) ? false : isFalsy(obj[key]);
    }
  });
};

const isFalsy = (val: any): boolean => {
  return val !== 0 && !val;
};

export default hasNull;
