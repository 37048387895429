import * as React from 'react';
import { TranslateIcon } from '@heroicons/react/solid';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import IconWrapper from '@SnackatCafe/snackat-ui/dist/IconWrapper';
import { ButtonVariant } from '@SnackatCafe/snackat-ui/dist/Button/types';
import { useRouter } from 'next/router';

import { useTranslationContext } from '../providers/TranslationProvider';
import useTranslateAddress from 'src/modules/address/hooks/useTranslateAddress';

export interface LanguagePickerProps {
  className?: string;
  compact?: boolean;
  variant?: ButtonVariant;
  hasIcon?: boolean;
}

const LanguagePicker: React.FC<LanguagePickerProps> = props => {
  const {
    className = '',
    compact = false,
    variant = 'muted',
    hasIcon = true,
  } = props;

  const { changeLanguage, language } = useTranslationContext();

  const router = useRouter();
  const { asPath } = router;
  const changeAddressLanguage = useTranslateAddress();

  const onClick = React.useCallback(() => {
    changeLanguage(language === 'ar' ? 'en' : 'ar');
  }, [language, asPath]);

  React.useEffect(() => {
    changeAddressLanguage();
  }, [language]);

  return (
    <div className={className} data-cy="cy-change-language-btn">
      {compact ? (
        <button
          onClick={onClick}
          className="flex items-center text-base font-bold text-secondary-500"
        >
          <IconWrapper icon={TranslateIcon} size={20} />
          <p className="mx-2">{language === 'ar' ? 'En' : 'ع'}</p>
        </button>
      ) : (
        <Button
          variant={variant}
          className={`w-full ${className}`}
          leftIcon={hasIcon ? TranslateIcon : null}
          onClick={onClick}
        >
          {language === 'ar' ? 'English' : 'العربية'}
        </Button>
      )}
    </div>
  );
};

export default LanguagePicker;
