import { useRouter } from 'next/router';

type Navigate = (
  urL: string,
  options?: {
    replace?: boolean;
    preserveQs?: boolean;
    resetScroll?: boolean;
    query?: Record<string, string>;
  }
) => void;

export const useNavigator = (): Navigate => {
  const router = useRouter();

  const navigate = (
    url: string,
    { replace = false, preserveQs = true, resetScroll = false, query = {} } = {}
  ) => {
    const targetUrl = preserveQs
      ? { pathname: url, query: { ...router.query, ...query } }
      : { pathname: url };

    if (replace) {
      router.replace(targetUrl, undefined, { shallow: true, scroll: false });
    } else {
      router.push(targetUrl, undefined, { shallow: true, scroll: false });
    }

    if (resetScroll) {
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  };

  return navigate;
};

export default useNavigator;
