import * as React from 'react';
// eslint-disable-next-line import/no-named-as-default
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';

import Navbar from './Navbar';
import NotificationsProvider from 'src/modules/notifications/providers/NotificationsProvider';
import { useLoadScript } from '@react-google-maps/api';
import { LIBRARIES } from 'src/modules/address/constants';
import { useTranslationContext } from '../providers/TranslationProvider';
import LoadingLayout from './LoadingLayout';
import { useGetServiceStatus } from 'src/modules/address/api/queries';
import { useLocationContext } from '../providers/LocationProvider';
import CloseApp from './CloseApp';
import { AVAILABLE_ROUTES } from '../constants';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';

const TypedRouter = Router as any;

const RootLayout: React.FC = ({ children }) => {
  const router = useRouter();
  TypedRouter.onRouteChangeStart = () => NProgress.start();
  TypedRouter.onRouteChangeError = () => NProgress.done();

  TypedRouter.onRouteChangeComplete = () => {
    NProgress.done();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { language } = useTranslationContext();
  const { userLocation } = useLocationContext();
  const { isAuthed } = useAuthContext();

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GEOCODE_API,
    version: 'weekly',
    libraries: LIBRARIES,
    language,
  });

  const {
    data: serviceStatus,
    isInitialLoading,
    status,
  } = useGetServiceStatus({
    countryId: userLocation?.country,
  });

  const isAboutAppRoute = router.pathname === '/myAccount/aboutApp';
  const isLocationRoute = router.pathname === '/[countryCode]';
  const isAvailableRoute = isAuthed
    ? AVAILABLE_ROUTES.includes(router.pathname.split('/')?.[1])
    : false;
  const loading = isLoaded || isInitialLoading;
  const isServiceUnavailable =
    !isLocationRoute &&
    !isAvailableRoute &&
    !serviceStatus?.ecommerceStatus &&
    status === 'success';

  return (
    <>
      {loading ? (
        isServiceUnavailable ? (
          <CloseApp />
        ) : (
          <div
            className={`flex h-full min-h-screen w-full max-w-full flex-col bg-backgrounds-500  text-sm font-medium text-secondary-500 antialiased  ${
              isAboutAppRoute &&
              'bg-appSize bg-topRight bg-no-repeat ltr:bg-appRoot rtl:bg-appRootRtl rtl:bg-topLeft md:bg-mdSize'
            }`}
          >
            <NotificationsProvider>
              <Navbar />
              {children}
            </NotificationsProvider>
          </div>
        )
      ) : (
        <LoadingLayout />
      )}
    </>
  );
};

export default RootLayout;
