import * as React from 'react';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import translateMapCityArea from '../utils/translateMapCityArea';

const useTranslateAddress = () => {
  const {
    currentLocation,
    latestLocation,
    mapPin,
    setCurrentLocation,
    setLatestLocation,
  } = useLocationContext();
  const { language } = useTranslationContext();

  const changeAddressLanguage = React.useCallback(async () => {
    try {
      if (
        currentLocation?.area?.language !== language &&
        !(currentLocation?.area?.name && currentLocation?.area?.arName) &&
        !!mapPin?.lat
      ) {
        const isCurrentLocEqLatest = // check if current location equal latest location
          mapPin?.lat?.toFixed(6) === latestLocation?.mapPin?.lat?.toFixed(6) &&
          mapPin?.lng?.toFixed(6) === latestLocation?.mapPin?.lng?.toFixed(6);

        const currentCity = currentLocation?.city;
        const currentArea = currentLocation?.area;
        const latestCity = currentLocation?.city;
        const latestArea = currentLocation?.area;

        const { trCity, trArea } = await translateMapCityArea(
          { location: mapPin, language },
          language
        );
        setCurrentLocation({
          area: {
            name: language === 'en' ? trArea : currentArea?.name,
            arName: language === 'ar' ? trArea : currentArea?.arName,
          },
          city: {
            name: language === 'en' ? trCity : currentCity?.name,
            arName: language === 'ar' ? trCity : currentCity?.arName,
          },
        });

        if (isCurrentLocEqLatest) {
          setLatestLocation({
            ...latestLocation,
            area: {
              name: language === 'en' ? trArea : latestArea?.name,
              arName: language === 'ar' ? trArea : latestArea?.arName,
            },
            city: {
              name: language === 'en' ? trCity : latestCity?.name,
              arName: language === 'ar' ? trCity : latestCity?.arName,
            },
          });
        } else {
          const { trCity, trArea } = await translateMapCityArea(
            { location: latestLocation?.mapPin, language },
            language
          );

          setLatestLocation({
            ...latestLocation,
            area: {
              name: language === 'en' ? trArea : latestArea?.name,
              arName: language === 'ar' ? trArea : latestArea?.arName,
            },
            city: {
              name: language === 'en' ? trCity : latestCity?.name,
              arName: language === 'ar' ? trCity : latestCity?.arName,
            },
          });
        }
      }
    } catch (error) {
      console.info(error);
    }
  }, [language]);

  return changeAddressLanguage;
};

export default useTranslateAddress;
