import { parseISO } from 'date-fns';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import ReactTimeAgo from 'react-time-ago';
import Image from '@SnackatCafe/snackat-ui/dist/Image';

import { ApiNotification } from '../api/types';
import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import { primaryColor } from 'src/styles';
import { useFilterContext } from 'src/modules/app/providers/FilterProvider';
import { useNavigator } from 'src/hooks/useNavigator/useNavigator';

const LONG_MESSAGE_LENGTH = 35;

interface NotificationProps {
  notification: ApiNotification;
}

const Notification: React.FC<NotificationProps> = props => {
  const { notification } = props;

  const { t } = useTranslation('common');

  const { language } = useTranslationContext();
  const navigate = useNavigator();

  const { setCategoryId, setBrandId } = useFilterContext();

  const [isShowMore, setIsShowMore] = React.useState(false);

  const extraData = notification?.extraData;

  const handleClick = () => {
    const page = extraData?.page;
    const rewardLineIds = extraData?.rewardLineIds?.[0];
    const bannerCategoryIds = extraData?.categoryIds?.[0];
    const bannerBrandIds = extraData?.brandIds;
    const bannerProductIds = extraData?.productList?.[0];
    bannerCategoryIds ? setCategoryId(bannerCategoryIds) : setCategoryId(0);
    !!bannerBrandIds?.[0] && setBrandId(bannerBrandIds);
    !!bannerProductIds && navigate(`products/${bannerProductIds}`);
    (!!bannerBrandIds?.[0] || !!bannerCategoryIds) && navigate('/products');
    !!page && navigate(`/reward`);
    !!rewardLineIds && navigate(`gift/${rewardLineIds}`);
  };

  const notificationMessage =
    language === 'ar' ? notification.message.ar : notification.message.en;

  const notificationTitle =
    language === 'ar' ? notification.title.ar : notification.title.en;

  const isLongMessage = notificationMessage.length > LONG_MESSAGE_LENGTH;

  const handleToggleShow = e => {
    e.stopPropagation();
    setIsShowMore(!isShowMore);
  };

  return (
    <div
      className={`relative cursor-pointer py-3 px-2 transition-all duration-200 hover:bg-opacity-60`}
      onClick={handleClick}
    >
      <div
        className={`absolute left-0 top-0 h-full bg-gray-100 transition-all duration-500 ${
          notification.isRead ? 'w-0' : 'w-full'
        }`}
      />
      <div className="relative z-10 mb-0.5 flex text-xs">
        <div
          className={`mt-1.5 h-1.5 rounded-full bg-primary-500 transition-all duration-200 ltr:mr-2 rtl:ml-2 ${
            notification.isRead ? 'w-0' : 'w-1.5'
          }`}
        />
        {extraData?.imageUrl128 && (
          <Image
            src={extraData?.imageUrl128}
            alt={notificationTitle}
            width={'50px'}
            height={'50px'}
            cover
            className="rounded-md ltr:mr-2 rtl:ml-2"
            loaderColor={primaryColor}
          />
        )}
        <div>
          <h3
            className={`font-semibold transition-colors duration-200 ${
              notification.isRead ? '' : 'text-primary-500'
            }`}
          >
            {notificationTitle}
          </h3>
          <p className="text-gray-600">
            {isLongMessage
              ? isShowMore
                ? notificationMessage
                : notificationMessage.slice(0, LONG_MESSAGE_LENGTH)
              : notificationMessage}
            {isLongMessage && !isShowMore && '...'}{' '}
            {isLongMessage ? (
              isShowMore ? (
                <button
                  className="ml-0.5 text-xs font-medium text-blue-500 underline"
                  onClick={handleToggleShow}
                >
                  {t('notifications.less')}
                </button>
              ) : (
                <button
                  className="ml-0.5 text-xs font-medium text-blue-500 underline"
                  onClick={handleToggleShow}
                >
                  {t('notifications.more')}
                </button>
              )
            ) : null}
          </p>
        </div>
      </div>

      <div className="text-xxs absolute top-2.5 z-10 text-gray-400  ltr:right-2 rtl:left-2 ">
        <ReactTimeAgo
          date={parseISO(notification.createdAt)}
          locale={language}
        />
      </div>
    </div>
  );
};

export default Notification;
