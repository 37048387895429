import _snakeCase from 'lodash/snakeCase';

function snakify<T>(obj: T): any {
  if (Array.isArray(obj)) {
    return obj.map(v => snakify(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_snakeCase(key)]: snakify(obj[key]),
      }),
      {}
    );
  }
  return obj;
}

export default snakify;
