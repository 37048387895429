import dynamic from 'next/dynamic';
import * as React from 'react';

import { Alert } from 'src/types';
import useAlert from 'src/hooks/useAlert';

const Toast = dynamic(() => import('../components/Toast'));

interface AppStateContextValue {
  notify: (alert: Alert) => void;
}

const AppStateContext = React.createContext(null as AppStateContextValue);

const AppStateProvider: React.FC = ({ children }) => {
  const [alert, setAlert] = useAlert();

  const notify = (args: Alert) => {
    setAlert({ ...args, title: args.title });
  };

  const value = {
    notify,
  };
  return (
    <AppStateContext.Provider value={value}>
      <div>{children}</div>
      {alert.message && <Toast alert={alert} />}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
export const useAppStateContext = (): AppStateContextValue =>
  React.useContext(AppStateContext);
