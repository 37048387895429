import { deleteClient, getClient, postClient } from 'src/api/restClient';
import { UseMutateHook } from 'src/api/types';
import useMutate from 'src/api/useMutate/useMutate';
import { ApiAddDevicePayload, ApiNotificationsCount } from './types';
import { NotificationsQueryKeysEnum } from './queries';
import { useQueryClient } from '@tanstack/react-query';

export const useRegisterDevice: UseMutateHook<
  void,
  ApiAddDevicePayload
> = () => {
  return useMutate(payload => postClient('/users/devices/', payload));
};

export const useMarkNotificationAsRead: UseMutateHook<void, number> = () => {
  const queryClient = useQueryClient();

  return useMutate(
    id => {
      return getClient(`/notifications/${id}/read/`);
    },
    {
      onMutate: async (notificationId: number) => {
        // FIXME:
        notificationId;
        // optimistically update unread counts
        await queryClient.cancelQueries([NotificationsQueryKeysEnum.Count]);
        const previousCountData = queryClient.getQueryData([
          NotificationsQueryKeysEnum.Count,
        ]);

        queryClient.setQueryData(
          [NotificationsQueryKeysEnum.Count],
          (old: ApiNotificationsCount) => ({ counts: old.counts - 1 })
        );

        // optimistically update said notification to be read

        await queryClient.cancelQueries([
          NotificationsQueryKeysEnum.Notifications,
        ]);
        const previousNotificationsData = queryClient.getQueryData([
          NotificationsQueryKeysEnum.Notifications,
        ]);

        return { previousCountData, previousNotificationsData };
      },
      onError: (
        _x,
        _y,
        context: {
          previousCountData;
          previousNotificationsData;
        }
      ) => {
        queryClient.setQueryData(
          [NotificationsQueryKeysEnum.Count],
          context.previousCountData
        );

        queryClient.setQueryData(
          [NotificationsQueryKeysEnum.Notifications],
          context.previousNotificationsData
        );
      },
    }
  );
};

export const useMarkAllNotifications: UseMutateHook<void, void> = () => {
  return useMutate(() => postClient('/notifications/read-all/', {}));
};

interface SubscribeUserToTopicVariables {
  registrationIds: string[];
}

export const useSubscribeUserToTopic: UseMutateHook<
  void,
  SubscribeUserToTopicVariables
> = () => {
  return useMutate(async vars => {
    const payload = {
      registrationIds: vars?.registrationIds,
    };
    await postClient('/users/topics/register/', payload);
  });
};

export const useUnSubscribeUserToTopic: UseMutateHook<
  void,
  SubscribeUserToTopicVariables
> = () => {
  return useMutate(async vars => {
    const payload = {
      registrationIds: vars?.registrationIds,
    };
    await postClient('/users/topics/unregister/', payload);
  });
};

interface DeleteUserDeviceVariables {
  registrationId: string;
}

export const useDeleteUserDevice: UseMutateHook<
  void,
  DeleteUserDeviceVariables
> = () => {
  return useMutate(async vars => {
    deleteClient(
      `/users/devices/?all=true&registration_id=${vars?.registrationId}`
    );
  });
};
