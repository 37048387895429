import HTTPStatus from 'http-status';
import * as React from 'react';
import { RefreshIcon, HomeIcon } from '@heroicons/react/solid';
import Button from '@SnackatCafe/snackat-ui/dist/Button';

import { useTranslation } from 'next-i18next';
import Link from 'src/components/Link';
import LottieWrapper from 'src/components/LottieWrapper/LottieWrapper';
import errorLottie from '../../../../public/lottie/error.json';

interface ErrorFallbackProps {
  code: number;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = props => {
  const { code = null } = props;

  const { t } = useTranslation(['not-found', 'common']);

  const title = code === 404 ? t('404') : HTTPStatus[code] || t('500');

  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center text-center">
      <div className="w-full">
        <LottieWrapper animationData={errorLottie} />
        <h1 className="mb-2 text-2xl font-bold uppercase leading-none text-primary-500">
          {t('error')} {code}
        </h1>
        <h6 className="mb-4">
          {t('title')} {title}
        </h6>
        <div className="flex items-center justify-center gap-2">
          <Link href={'/'}>
            <Button
              variant="solid"
              leftIcon={HomeIcon}
              className="hover:bg-white hover:text-primary-500"
            >
              {t('buttons.home')}
            </Button>
          </Link>

          <Button
            variant="ghost"
            onClick={() => window.location.reload()}
            leftIcon={RefreshIcon}
          >
            {t('common:buttons.refresh')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
