import * as React from 'react';
import Drawer from '@SnackatCafe/snackat-ui/dist/Drawer';
import type { DrawerProps } from '@SnackatCafe/snackat-ui';

import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';

const DraweR: React.FC<DrawerProps> = props => {
  const { children, ...rest } = props;

  const { isRTL } = useTranslationContext();

  return (
    <Drawer {...rest} dir={isRTL ? 'rtl' : 'ltr'}>
      <div dir={isRTL ? 'rtl' : 'ltr'}>
        <div className="ltr:font-ltr rtl:font-rtl">{children}</div>
      </div>
    </Drawer>
  );
};

export default DraweR;
