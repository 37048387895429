import { getClient, postClient } from 'src/api/restClient';
import { UseGetHook } from 'src/api/types';
import useGet from 'src/api/useGet';
import { ApiOrder, ApiOrdersList } from 'src/modules/myOrders/api/types';
import {
  ApiCart,
  ApiCartQuantity,
  ApiCartUpdate,
  ApiFreeDeliveryProd,
} from './types';
import { useQueryClient } from '@tanstack/react-query';

export enum CartQueryKeyEnum {
  cartData = 'cart-data',
  activeCart = 'active-cart',
  cartDetails = 'cart-details',
  cartQuantity = 'cart-quantity',
  freeDeliveryProd = 'free-delivery-prod',
}

interface GetCartDataVariables {
  orderId: number;
  locationIds: number[];
}

export const useGetCartData: UseGetHook<ApiCart, GetCartDataVariables> = (
  vars,
  options
) => {
  return useGet(
    {
      queryKey: [CartQueryKeyEnum.cartData, vars],
      queryFn: async () => {
        const data: ApiCart = await getClient(
          `/odoo/order/${vars.orderId}/?location_ids=[${vars.locationIds}]`
        );
        return data;
      },
    },
    {
      ...options,
    }
  );
};

interface GetActiveCartVariables {
  invalidate?: boolean;
  product?: number;
}

export const useGetActiveCart: UseGetHook<ApiOrder, GetActiveCartVariables> = (
  vars,
  options
) => {
  const queryClient = useQueryClient();
  return useGet(
    {
      queryKey: [CartQueryKeyEnum.activeCart, vars],
      queryFn: async () => {
        const data: ApiOrdersList = await getClient(
          '/odoo/order/list/?status=draft'
        );
        const values = !data?.orderDetails?.at(-1)
          ? null
          : data?.orderDetails?.at(-1);
        return values;
      },
      optionalVariables: ['invalidate', 'product'],
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [CartQueryKeyEnum.cartData],
        });
      },
      ...options,
    }
  );
};

interface GetCartDetailsVariables {
  orderId: number;
}

export const useGetCartDetails: UseGetHook<
  ApiCartUpdate,
  GetCartDetailsVariables
> = (vars, options) => {
  return useGet(
    {
      queryKey: [CartQueryKeyEnum.cartData, vars],
      queryFn: async () => {
        const data: ApiCartUpdate = await postClient(
          `/odoo/order/${vars.orderId}/delivery-cost/`,
          {}
        );
        return data;
      },
    },
    options
  );
};

interface GetCartQuantityVariables {
  locationIds: number[];
  productsIds: number[];
  id: number;
}

export const useGetCartQuantity: UseGetHook<
  ApiCartQuantity,
  GetCartQuantityVariables
> = (vars, options) => {
  return useGet(
    {
      queryKey: [CartQueryKeyEnum.cartQuantity, vars],
      queryFn: async () => {
        const data: ApiCartQuantity[] = await getClient(
          `/odoo/products/quantity/?location_ids=[${vars.locationIds}]&product_ids=[${vars.productsIds}]`
        );
        const [qty] = data.filter(item => item.id === vars.id);
        return qty;
      },
    },
    options
  );
};

interface GetFreeDeliveryProdVariables {
  countryId: number;
}

export const useGetFreeDeliveryProd: UseGetHook<
  number[],
  GetFreeDeliveryProdVariables
> = (vars, options) => {
  return useGet(
    {
      queryKey: [CartQueryKeyEnum.freeDeliveryProd, vars],
      queryFn: async () => {
        const data: any = await getClient(`/utile/configs/${vars?.countryId}/`);
        const productIds: ApiFreeDeliveryProd = data?.result?.data;
        return productIds?.productIds;
      },
    },
    options
  );
};
