import Button from '@SnackatCafe/snackat-ui/dist/Button';
import { Transition } from '@headlessui/react';
import * as React from 'react';
import { useTranslation } from 'next-i18next';

interface NotificationsEnablePromptProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const NotificationsEnablePrompt: React.FC<
  NotificationsEnablePromptProps
> = props => {
  const { isOpen, onClose, onAccept } = props;
  const { t } = useTranslation('common');

  return (
    <Transition as={React.Fragment} appear show={isOpen}>
      <div className="fixed top-16 z-30 shadow-md ltr:right-4 rtl:left-4">
        <Transition.Child
          as="div"
          enter="transition ease-out duration-500"
          enterFrom="transform translate-x-full"
          enterTo="transform translate-x-0"
          leave="transition ease-in duration-200"
          leaveFrom="transform translate-x-0"
          leaveTo="transform translate-x-full"
        >
          <div className="shadow-uniform inline-block w-full max-w-sm transform rounded-lg bg-white py-6 px-4 text-left align-middle text-gray-700 transition-all">
            <div className="mb-2 text-sm font-medium rtl:text-right">
              {t('notifications.title')}
            </div>
            <div className="flex items-center justify-end">
              <Button variant="text" onClick={onClose} rounded="full">
                {t('buttons.cancel')}
              </Button>
              <Button
                variant="solid"
                className="shadow-none"
                onClick={onAccept}
                rounded="full"
              >
                {t('notifications.enable')}
              </Button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

export default NotificationsEnablePrompt;
