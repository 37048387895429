import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { useTranslationContext } from '../providers/TranslationProvider';
import { useGetServiceStatus } from 'src/modules/address/api/queries';
import { useLocationContext } from '../providers/LocationProvider';
import Image from '@SnackatCafe/snackat-ui/dist/Image';
import { primaryColor } from 'src/styles';
import LogoIcon from 'src/components/LogoIcon';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import useNavigator from 'src/hooks/useNavigator';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { ClockIcon, UserIcon } from '@heroicons/react/outline';

const CloseApp: React.FC = () => {
  const { t } = useTranslation(['common', 'navbar']);

  const navigate = useNavigator();
  const { language } = useTranslationContext();
  const { userLocation } = useLocationContext();
  const { isAuthed } = useAuthContext();
  const { data: serviceStatus } = useGetServiceStatus({
    countryId: userLocation?.country,
  });

  const serviceStatusMessage =
    language === 'ar'
      ? serviceStatus?.arEcommerceMessage
      : serviceStatus?.ecommerceMessage;
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <LogoIcon goesToLanding={false} size={160} />
      <Image
        src="/images/service-status.svg"
        width={300}
        height={300}
        alt={serviceStatusMessage}
        cover
        loaderColor={primaryColor}
      />
      <p className="w-2/3 text-center font-semibold text-icons-500">
        {serviceStatusMessage}
      </p>
      {isAuthed && (
        <div className="m-4 flex flex-col items-center justify-center gap-y-3 rounded-3xl bg-primary-50 p-4 text-center">
          <p>{t('closeApp.subTitle')}</p>
          <div className="flex items-center justify-center gap-x-4">
            <Button
              variant="solid"
              rounded="full"
              onClick={() => navigate('/myAccount')}
              leftIcon={UserIcon}
            >
              {t('navbar:links.myAccount')}
            </Button>
            <Button
              variant="solid"
              rounded="full"
              onClick={() => navigate('/myOrders')}
              leftIcon={ClockIcon}
            >
              {t('navbar:links.myOrders')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CloseApp;
