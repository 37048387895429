import * as React from 'react';
import {
  ClockIcon,
  SearchIcon,
  UserIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import SearchFilter from 'src/components/SearchFilter/SearchFilter';
import NavLink from 'src/components/NavLink/NavLink';
import BottomNavigation from 'src/components/BottomNavigation/BottomNavigation';
import useMobile from 'src/hooks/useMobile';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { useUserContext } from 'src/modules/auth/providers/UserProvider';
import useTablet from 'src/hooks/useTablet';
import LogoIcon from 'src/components/LogoIcon';
import BackButton from 'src/components/BackButton/BackButton';
import CartButton from 'src/components/CartButton/CartButton';
import GiftButton from 'src/components/GiftButton/GiftButton';

import LanguagePicker from './LanguagePicker';
import NotificationsCentre from 'src/modules/notifications/components/NotificationsCentre';
import { NotificationButton } from 'src/components/NotificationButton/NotificationButton';

const Navbar: React.FC = () => {
  const { t } = useTranslation('navbar');
  const router = useRouter();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const { isAuthed } = useAuthContext();
  const { user } = useUserContext();

  const isLocationRoute =
    router.pathname === '/[countryCode]' || router.pathname === '/';
  const isMainRoute = router.pathname === '/home';
  const isProductsRoute = router.pathname === `/products`;
  const isProductRoute = router.pathname === `/products/[productId]`;
  const isSearchRoute = router.pathname === '/search';
  const isAboutAppRoute = router.pathname === '/myAccount/aboutApp';
  const isCartRoute = router.pathname === '/cart';
  const isReferralRoute = router.pathname === '/referral';
  const isPaymentRoute = router.pathname === '/payment';
  const isDownloadAppRoute = router.pathname === '/download-app';

  const showSearchFilterBar = isMainRoute || isProductsRoute || isSearchRoute;
  const hideBackButton =
    isMainRoute || isLocationRoute || isProductsRoute || isAboutAppRoute;
  const showBottomNavigation =
    !isLocationRoute &&
    !isCartRoute &&
    isMobile &&
    !isReferralRoute &&
    !isPaymentRoute &&
    !isProductRoute &&
    !isDownloadAppRoute;

  const links = [
    {
      link: `/products`,
      icon: ViewGridIcon,
      name: t('links.products'),
    },
    {
      link: '/myOrders',
      icon: ClockIcon,
      name: t('links.myOrders'),
    },
    {
      link: '/search',
      icon: SearchIcon,
      name: t('links.search'),
    },
    {
      link: '/myAccount',
      icon: UserIcon,
      name: isAuthed
        ? `${t('titles.hello')}, ${user?.fullName}`
        : t('links.myAccount'),
    },
  ];

  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);

  const toggleNotifications = React.useCallback(
    () => setIsNotificationsOpen(!isNotificationsOpen),
    []
  );

  return (
    <div className="relative w-full" data-cy="cy-navbar">
      <NotificationsCentre
        isOpen={isNotificationsOpen}
        onClose={() => setIsNotificationsOpen(false)}
      />

      <div
        className={`absolute top-0 mb-2 flex w-fit items-center px-4 pt-4 ltr:right-0 rtl:left-0  md:relative md:mb-0 md:w-full md:justify-between md:px-10 md:pt-0 ${
          isLocationRoute && 'md:shadow'
        } ${isMobile && !isLocationRoute && !isMainRoute && 'pt-4'}`}
      >
        <div
          className={` ${
            isMainRoute || isProductsRoute || isSearchRoute
              ? 'md:justify-between'
              : 'md:justify-start'
          } flex w-full items-center justify-center gap-x-4 md:pl-0  md:rtl:pl-10`}
        >
          {!isMobile && <LogoIcon goesToLanding={!isLocationRoute && true} />}
          {!isLocationRoute && (
            <ul className="hidden w-2/3 items-center justify-start gap-x-2 md:flex  lg:gap-x-6">
              {links.map((link, i) => (
                <li
                  key={i}
                  className="cursor-pointer font-normal text-icons-500 hover:text-primary-500 md:text-sm lg:text-base"
                >
                  <NavLink href={link.link} icon={!isTablet && link.icon}>
                    {link.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
          {showSearchFilterBar && (
            <div className="hidden md:flex">
              <SearchFilter />
            </div>
          )}
        </div>
        <div className={`z-10 flex items-center gap-x-2`}>
          <LanguagePicker
            className="hidden md:flex"
            variant={isAboutAppRoute ? 'ghost' : 'muted'}
          />
          <GiftButton
            hide={isAboutAppRoute || isLocationRoute}
            light={isReferralRoute && isMobile}
          />
          <CartButton
            hide={isAboutAppRoute || isLocationRoute}
            active={isCartRoute}
            light={isReferralRoute && isMobile}
          />
          <NotificationButton
            toggleNotifications={toggleNotifications}
            hide={!isAuthed || isLocationRoute}
            active={isNotificationsOpen}
            light={isReferralRoute && isMobile}
          />
        </div>
      </div>
      <BackButton hide={hideBackButton} light={isReferralRoute} />
      {showBottomNavigation && <BottomNavigation />}
    </div>
  );
};

export default Navbar;
