import { setExtras } from '@sentry/nextjs';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router';
import { TOKEN_NAME } from 'src/modules/auth/constants';
import { camelize, isBrowser, snakify } from 'src/utils';

const baseURL = `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}`;

export const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  async config => {
    const requestToken = Cookies.get(TOKEN_NAME);
    const Authorization = requestToken ? `Bearer ${requestToken}` : null;

    config.headers = {
      Authorization,
      'content-Type': 'application/json',
    };
    return { ...config, data: snakify(config.data) };
  },
  (error: AxiosError) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return { ...response, data: camelize(response.data) };
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      Cookies.remove(TOKEN_NAME);
      localStorage.removeItem('user');
      if (isBrowser()) {
        Router.replace({ pathname: '/login', query: Router.query });
      }
    }

    return Promise.reject(error);
  }
);

export const getClient = async <TApiResponseData = unknown>(
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  options?
): Promise<TApiResponseData> => {
  const response = await axiosInstance.get<TApiResponseData>(endpoint, options);
  setExtras({
    status: response.status,
    response: response.request.response,
    message: response.request.message,
    URL: response.request.responseURL,
    request: response.config.data,
  });
  return response.data;
};

export const postClient = async <TApiResponseData = unknown>(
  endpoint: string,
  payload: Record<string, any>
): Promise<TApiResponseData> => {
  const response = await axiosInstance.post<TApiResponseData>(
    endpoint,
    payload
  );
  setExtras({
    status: response.status,
    response: response.request.response,
    message: response.request.message,
    URL: response.request.responseURL,
    request: response.config.data,
  });
  return response.data;
};

export const patchClient = async <TApiResponseData = unknown>(
  endpoint: string,
  payload: Record<string, any>
): Promise<TApiResponseData> => {
  const response = await axiosInstance.patch<TApiResponseData>(
    endpoint,
    payload
  );
  setExtras({
    status: response.status,
    response: response.request.response,
    message: response.request.message,
    URL: response.request.responseURL,
    request: response.config.data,
  });
  return response.data;
};

export const optionsClient = async <TApiResponseData = unknown>(
  endpoint: string,
  payload?: Record<string, any>
): Promise<TApiResponseData> => {
  const response = await axiosInstance.options<TApiResponseData>(endpoint, {
    data: payload,
  });
  setExtras({
    status: response.status,
    response: response.request.response,
    message: response.request.message,
    URL: response.request.responseURL,
    request: response.config.data,
  });
  return response.data;
};

export const putClient = async <TApiResponseData = unknown>(
  endpoint: string,
  payload: Record<string, any>
): Promise<TApiResponseData> => {
  const response = await axiosInstance.put<TApiResponseData>(endpoint, payload);
  setExtras({
    status: response.status,
    response: response.request.response,
    message: response.request.message,
    URL: response.request.responseURL,
    request: response.config.data,
  });
  return response.data;
};

export const deleteClient = async <TApiResponseData = unknown>(
  endpoint: string
): Promise<TApiResponseData> => {
  const response = await axiosInstance.delete<TApiResponseData>(endpoint);
  setExtras({
    status: response.status,
    response: response.request.response,
    message: response.request.message,
    URL: response.request.responseURL,
    request: response.config.data,
  });
  return response.data;
};

export const postImageClient = async <TApiResponseData = unknown>(
  endpoint: string,
  imageFile: File
): Promise<TApiResponseData> => {
  const dataForm = new FormData();
  dataForm.append('image_file', imageFile, imageFile.name);

  const data = await postClient<TApiResponseData>(endpoint, dataForm);

  return data;
};

export const postFileClient = async <TApiResponseData = unknown>(
  endpoint: string,
  file: File
): Promise<TApiResponseData> => {
  const dataForm = new FormData();
  dataForm.append('file', file, file.name);

  const data = await postClient<TApiResponseData>(endpoint, dataForm);

  return data;
};

// delete bulk of records through payload in body
export const deleteBulkClient = async <TApiResponseData = unknown>(
  endpoint: string,
  payload: Record<string, any>
): Promise<TApiResponseData> => {
  const response = await axiosInstance.post<TApiResponseData>(
    endpoint,
    payload
  );
  return response.data;
};
