import { useState } from 'react';

import { Alert } from 'src/types';

const useAlert = (): [a: Alert, b: (val: Alert) => void] => {
  const [alert, setAlert] = useState<Alert>({ status: null, message: null });

  return [alert, setAlert];
};

export default useAlert;
