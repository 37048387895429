import { InputOption } from 'src/types';

const selectedOption = (
  val: InputOption['value'],
  options: InputOption[]
): InputOption => {
  return options?.find(({ value }) => value === val);
};

export default selectedOption;
