import * as React from 'react';
import { useLocalStorage } from 'react-use';
import { useRouter } from 'next/router';

import LoadingLayout from '../components/LoadingLayout';

interface TranslationContextValue {
  changeLanguage: (newLanguage: string) => void;
  language: 'en' | 'ar';
  isRTL: boolean;
}

const TranslationContext = React.createContext(null as TranslationContextValue);

const TranslationProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useLocalStorage('language', 'ar');
  const router = useRouter();
  const { asPath } = router;

  React.useEffect(() => {
    if (router.locale !== language && ['ar', 'en'].includes(language)) {
      router.push(asPath, asPath, { locale: language });
    }
  }, []);

  const changeLanguage = (newLanguage: string): void => {
    router.push(asPath, asPath, { locale: newLanguage });
    setLanguage(newLanguage);
  };

  const isRTL = language === 'ar';

  const value = {
    changeLanguage,
    language: router.locale as 'en' | 'ar',
    isRTL,
  };

  return (
    <TranslationContext.Provider value={value}>
      {router.locale !== language ? (
        <LoadingLayout />
      ) : (
        <div
          dir={isRTL ? 'rtl' : 'ltr'}
          className={`${isRTL ? 'font-rtl' : 'font-ltr'}`}
        >
          {children}
        </div>
      )}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
export const useTranslationContext = (): TranslationContextValue =>
  React.useContext(TranslationContext);
