import { useQueryClient } from '@tanstack/react-query';
import { patchClient, postClient } from 'src/api/restClient';
import { UseMutateHook } from 'src/api/types';
import useMutate from 'src/api/useMutate';
import { CartQueryKeyEnum } from 'src/modules/cart/api/queries';
import { ApiAddToCartPayload, ApiCartUpdate, ApiProductInCart } from './types';
import { captureException, setExtras } from '@sentry/nextjs';

interface MutateAddToCartVariables {
  saleOrderId?: number;
  cart: ApiProductInCart[];
  locationId: number[];
  customerId?: number;
  addressId?: number;
  instruction?: string;
  companyId: number;
  countryId: number;
  deliveryCharges: number;
  deliveryTimeInfo?: {
    deliveryDate: string | boolean;
    deliveryDay: string | boolean;
    fromTime: string | boolean;
    toTime: string | boolean;
  };
}

export const useMutateAddToCart: UseMutateHook<
  { data: ApiCartUpdate; vars: MutateAddToCartVariables },
  MutateAddToCartVariables
> = () => {
  const queryClient = useQueryClient();
  return useMutate(
    async vars => {
      const payload: ApiAddToCartPayload = {
        cart: vars.cart,
        customerId: vars.customerId,
        locationIds: vars.locationId,
        addressId: vars.addressId,
        instruction: vars.instruction,
        orderPlatform: 'website',
        companyId: vars.companyId,
        countryId: vars.countryId,
        deliveryCharges: vars.deliveryCharges,
        deliveryTimeInfo: vars?.deliveryTimeInfo,
      };
      const isUpdating = !!vars.saleOrderId;
      const data: ApiCartUpdate = !isUpdating
        ? await postClient('/odoo/order/', payload)
        : await patchClient(`/odoo/order/${vars.saleOrderId}/`, payload);
      return { data, vars };
    },
    {
      onSuccess: ({ data, vars }) => {
        if (
          !data?.orderDetails?.cartDetails?.cartLines?.length &&
          !vars?.cart?.every(item => item?.setQty === -1)
        ) {
          setExtras({
            request: vars,
            response: data,
          });
          captureException(new Error('Empty Cart Error'));
        }
        queryClient.invalidateQueries({
          queryKey: [CartQueryKeyEnum.cartData],
        });
        queryClient.invalidateQueries({
          queryKey: [CartQueryKeyEnum.activeCart],
        });
      },
    }
  );
};
