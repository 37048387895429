import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import cartReducer from './cart/cartSlice';
import Cookies from 'js-cookie';

const persistConfig = {
  key: 'root',
  storage,
};
const isAuthed = !!Cookies.get('accessToken');
const persistedReducer = persistReducer(persistConfig, cartReducer);
const pReducer = isAuthed ? cartReducer : persistedReducer;

export const store = configureStore({
  reducer: pReducer as any,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
