import { Transition } from '@headlessui/react';
import * as React from 'react';
import { useClickAway } from 'react-use';
import { useTranslation } from 'next-i18next';

import useMobile from 'src/hooks/useMobile';
import NotificationsList from './NotificationsList';
import Drawer from 'src/components/Drawer/Drawer';

interface NotificationsCentreProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotificationsCentre: React.FC<NotificationsCentreProps> = props => {
  const { isOpen, onClose } = props;

  const isMobile = useMobile();

  const ref = React.useRef(null);

  useClickAway(ref, () => {
    if (!isOpen) return;
    onClose();
  });

  return (
    <>
      {!isMobile ? (
        <div ref={ref} className="fixed top-12 z-20 ltr:right-4 rtl:left-4">
          <Transition
            as="div"
            show={isOpen}
            className="max-w-xs overflow-hidden rounded-lg bg-white shadow"
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95 -translate-y-10"
            enterTo="transform opacity-100 scale-100 translate-y-0"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Notifications />
          </Transition>
        </div>
      ) : (
        <Drawer
          hasHeader={false}
          isOpen={isOpen}
          onClose={onClose}
          direction="horizontal"
          className="m-0 p-0"
        >
          <Notifications />
        </Drawer>
      )}
    </>
  );
};

const Notifications = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="flex items-center justify-between gap-x-1 border-b border-gray-200 px-2 py-2 text-sm font-semibold shadow-sm">
        <h2>{t('notifications.NotifyTitle')}</h2>
      </div>
      <NotificationsList />
    </>
  );
};

export default NotificationsCentre;
