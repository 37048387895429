import Cookies from 'js-cookie';
import { SESSION_EXPIRY } from 'src/constants';

export default class TokenCookie {
  key: string;
  constructor(key: string) {
    this.key = key;
  }

  get(): string {
    return Cookies.get(this.key);
  }

  set(value: string): void {
    Cookies.set(this.key, value, { expires: SESSION_EXPIRY });
  }

  clear(): void {
    Cookies.remove(this.key);
  }
}
