import * as React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useErrorBoundaryContext } from 'src/modules/error/ErrorBoundaryProvider';
import hasNull from 'src/utils/hasNull';
import {
  QueryObject,
  UseInfiniteGetOptions,
  UseInfiniteGetResult,
} from '../types';

let _count = null;

const DEFAULT_OPTIONS = {
  enabled: true,
};

type UseInfiniteGet = <T, U>(
  qo: QueryObject<T>,
  options?: UseInfiniteGetOptions<T, U>
) => UseInfiniteGetResult<T, U>;

const useInfiniteGet: UseInfiniteGet = (
  { queryKey, queryFn, optionalVariables },
  options = DEFAULT_OPTIONS
) => {
  const { onQueryError } = useErrorBoundaryContext();

  const result = useInfiniteQuery(queryKey, queryFn, {
    getNextPageParam: (lastPage: any, allPages) => {
      const maxPages = lastPage.totalPages;
      const nextPage = allPages.length + 1;
      return nextPage <= maxPages ? nextPage : undefined;
    },
    onError: error => onQueryError(result.refetch, error),
    ...options,
    enabled: !hasNull(queryKey, optionalVariables) && options.enabled,
    select: data => {
      const count = (data?.pages[0] as any)?.totalPages;

      const flattened: any = data?.pages?.reduce((acc, item) => {
        const items =
          item?.data === undefined ? (item as any).results : (item as any).data;
        return [...acc, ...items];
      }, []);

      const newData = options.select ? options.select(flattened) : flattened;

      _count = count;

      return { data: newData, count } as any;
    },
  });

  React.useEffect(() => {
    if (options.onData && result.data) {
      options.onData(result.data);
    }
  }, [result.data]);

  return {
    ...result,
    data: result?.data?.data,
    count: result?.data?.count || _count,
  };
};

export default useInfiniteGet;
