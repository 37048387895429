import * as React from 'react';
import { setUser } from '@sentry/nextjs';
import { useUserContext } from 'src/modules/auth/providers/UserProvider';

const TrackingProvider: React.FC = ({ children }) => {
  const { user } = useUserContext();

  React.useEffect(() => {
    setUser({
      id: user?.id,
      username: user?.fullName,
      email: user?.email,
    });
  }, [user]);

  return <>{children}</>;
};

export default TrackingProvider;
