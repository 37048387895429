import * as React from 'react';
import { useCookie, useLocalStorage } from 'react-use';

import { SESSION_EXPIRY, TOKEN_NAME } from '../constants';
import { Currency, PhoneFormValues, User } from '../types';
import { useRouter } from 'next/router';
import { getUrlQueries } from 'src/utils/getUrlQueries';
import { getUserCurrency } from 'src/utils/getUserCurrency';
import { CountryCode } from 'src/types';
import getUserCountry from 'src/utils/getUserCountry';

interface UserContextValue {
  user: User;
  userData: (data: any) => void;
  token: string;
  clearUser: () => void;
  clearToken: () => void;
  userPhone: PhoneFormValues;
  setUserPhone: React.Dispatch<React.SetStateAction<PhoneFormValues>>;
  instructions: string;
  setInstructions: React.Dispatch<React.SetStateAction<string>>;
  clearInstructions: () => void;
  currency: Currency;
  country: CountryCode;
}

const UserContext = React.createContext(null as UserContextValue);

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser, clearUser] = useLocalStorage<User>('user');
  const [token, setToken, clearToken] = useCookie(TOKEN_NAME);
  const [instructions, setInstructions, clearInstructions] =
    useLocalStorage<string>('instructions');
  const [userPhone, setUserPhone] = React.useState<PhoneFormValues>();

  const userData = (data: any) => {
    setUser(data.user);
    setToken(data.accessToken, { expires: SESSION_EXPIRY });
  };

  const router = useRouter();

  const queries = getUrlQueries(router?.asPath);
  const { country: countryId } = queries ? queries : router.query;

  const currency: Currency = getUserCurrency(parseInt(countryId));
  const country: CountryCode = getUserCountry(countryId);

  const value = {
    user,
    userData,
    token,
    clearUser,
    clearToken,
    userPhone,
    setUserPhone,
    instructions,
    setInstructions,
    clearInstructions,
    currency,
    country,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
export const useUserContext = (): UserContextValue =>
  React.useContext(UserContext);
