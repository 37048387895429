import { ChevronLeftIcon } from '@heroicons/react/outline';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import { useRouter } from 'next/router';
import * as React from 'react';
import useNavigator from 'src/hooks/useNavigator';

interface BackButtonProps {
  hide?: boolean;
  light?: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({ hide, light }) => {
  const router = useRouter();
  const navigate = useNavigator();

  const isCartRoute = router.pathname === '/cart';
  return (
    <Button
      leftIcon={ChevronLeftIcon}
      variant="text"
      className={`absolute top-4 z-10 m-0 p-0 ${
        light ? 'text-white' : 'text-secondary-500'
      } ltr:left-4 rtl:right-4 rtl:rotate-180 md:hidden`}
      iconProps={{ size: 20 }}
      onClick={() => {
        isCartRoute ? navigate('/home') : router.back();
      }}
      hidden={hide}
    />
  );
};

export default BackButton;
