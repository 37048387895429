import { getGeocode } from 'use-places-autocomplete';

const translateMapCityArea = async (request, language) => {
  const translatedResults = await getGeocode({
    ...request,
    language,
  });
  const [translatedAddress] = translatedResults.filter(
    res =>
      res.types.includes('point_of_interest') ||
      res.types.includes('establishment') ||
      res.types.includes('street_address') ||
      res.types.includes('route')
  );
  const translatedSelectedAddress = translatedAddress
    ? translatedAddress
    : translatedResults[0];
  const [trCity] = translatedSelectedAddress.address_components.filter(res =>
    res.types.includes('locality')
      ? res.types.includes('locality')
      : res.types.includes('administrative_area_level_1')
  );
  const [area] = translatedSelectedAddress.address_components.filter(
    res =>
      res.types.includes('sublocality') ||
      res.types.includes('point_of_interest') ||
      res.types.includes('establishment') ||
      res.types.includes('neighborhood')
  );
  const [routeArea] = translatedSelectedAddress.address_components.filter(
    res => res.types.includes('route') || res.types.includes('plus_code')
  );
  const selectedArea = area?.short_name
    ? area?.short_name
    : routeArea?.short_name;

  return { trCity: trCity?.short_name, trArea: selectedArea };
};

export default translateMapCityArea;
