import * as React from 'react';
import {
  ClockIcon,
  SearchIcon,
  UserIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';

import LogoIcon from '../LogoIcon';
import NavLink from '../NavLink/NavLink';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const BottomNavigation: React.FC = () => {
  const { t } = useTranslation('navbar');
  const router = useRouter();
  const isHomeRoute = router.pathname === '/home';
  const links = [
    {
      link: '/myOrders',
      icon: ClockIcon,
      name: t('links.myOrders'),
    },
    {
      link: '/products',
      icon: ViewGridIcon,
      name: t('links.products'),
    },

    {
      link: '/home',
      icon: null,
      name: t('links.home'),
    },
    {
      link: '/search',
      icon: SearchIcon,
      name: t('links.search'),
    },
    {
      link: '/myAccount',
      icon: UserIcon,
      name: t('links.myAccount'),
    },
  ];
  return (
    <div
      className=" fixed left-0 bottom-0 z-10 h-[95px]  w-full bg-bottomNav bg-topCenter text-center"
      data-cy="cy-navbar"
    >
      <ul className=" absolute bottom-4 flex w-full items-end justify-between px-2">
        {links.map((link, i) => (
          <li
            key={i}
            className="cursor-pointer text-xs font-light text-icons-500 hover:text-primary-500 rtl:text-sm lg:text-base"
            data-cy="cy-bottom-nav-link"
          >
            <NavLink
              href={link.link}
              icon={link.icon}
              iconSize={20}
              className="flex-col"
            >
              {link.name !== t('links.home') ? (
                link.name
              ) : (
                <div className=" absolute bottom-0 left-1/2 flex h-[70px] -translate-x-1/2 flex-col justify-between">
                  <div
                    className={`relative h-12 w-12 rounded-full ${
                      isHomeRoute
                        ? ' bg-primary-200 backdrop-opacity-30'
                        : ' bg-white backdrop-opacity-30'
                    } `}
                  >
                    <div className=" absolute top-1/2 left-1/2 z-10 -translate-y-1/2 -translate-x-1/2 rounded-full">
                      <LogoIcon icon size={25} goesToLanding />
                    </div>
                  </div>
                  <div>{link.name}</div>
                </div>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BottomNavigation;
