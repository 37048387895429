import { useRouter } from 'next/router';
import queryString from 'query-string';

import { SingleActionParam } from 'src/types';

const useDialogActionHandlers = <
  Action extends string,
  Params = SingleActionParam
>(): [
  open: (action: Action, newParams?: Params) => Promise<any>,
  close: () => Promise<any>
] => {
  const router = useRouter();

  const close = () => {
    delete router.query.id;
    delete router.query.action;
    let prevParams = sessionStorage.getItem('prev-params') as any;

    if (prevParams) {
      prevParams = JSON.parse(prevParams);
    }

    setTimeout(() => {
      sessionStorage.removeItem('prev-params');
    }, 500);

    return router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, ...(prevParams || {}) },
      },
      undefined,
      { shallow: true }
    );
  };

  const open = (action: Action, newParams?: Params) => {
    const _prevParams = queryString.parse(window.location.search);

    sessionStorage.setItem('prev-params', JSON.stringify(_prevParams));

    delete _prevParams.id;

    return router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          ...newParams,
          action,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  return [open, close];
};

export default useDialogActionHandlers;
