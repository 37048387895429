import * as React from 'react';
import storage from 'redux-persist/lib/storage';

import { useUserContext } from './UserProvider';
import { useRouter } from 'next/router';
import { useDeleteUserDevice } from 'src/modules/notifications/api/mutations';
import { getMessagingToken } from 'src/scripts/firebase';

interface AuthContextValue {
  logout: () => void;
  isAuthed: boolean;
}

const AuthContext = React.createContext(null as AuthContextValue);

const AuthProvider: React.FC = ({ children }) => {
  const router = useRouter();

  const { token, clearUser, clearToken, user } = useUserContext();

  const isAuthed = !!token;
  const { mutate: deleteUserDevice } = useDeleteUserDevice();

  const resetUser = () => {
    clearUser();
    clearToken();
    storage.removeItem('persist:root');
    router.reload();
    localStorage.setItem('address-id', 'LATEST-MAP-PIN');
  };

  const logout = async () => {
    const FCMToken = await getMessagingToken();
    if (FCMToken) {
      deleteUserDevice(
        {
          registrationId: FCMToken,
        },
        {
          onSuccess: () => {
            resetUser();
          },
        }
      );
    } else {
      resetUser();
    }
  };

  React.useEffect(() => {
    if (!isAuthed && !!user?.fullName) {
      clearUser();
      storage.removeItem('persist:root');
      router.reload();
    }
  }, [isAuthed, user?.fullName]);

  const value = {
    logout,
    isAuthed,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextValue =>
  React.useContext(AuthContext);
export default AuthProvider;
