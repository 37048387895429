import { ApiCountry } from './api/types';

export const COUNTRIES_OPTIONS: ApiCountry[] = [
  {
    name: 'United Arab Emirates',
    arName: 'الإمارات العربية المتحدة',
    id: 2,
    currencyName: 'AED',
    code: 'AE',
    arCurrencyName: 'درهم',
    commingSoon: false,
    flag: 'https://snackat-cafe-test-12278090.dev.odoo.com/base/static/img/country_flags/ae.png',
    countryKey: '+971',
    companyId: 1,
    center: { lat: 24.4539, lng: 54.3773 },
  },
  {
    name: 'Oman',
    arName: 'سلطنة عمان',
    id: 171,
    currencyName: 'OMR',
    code: 'OM',
    arCurrencyName: 'ر.ع.',
    commingSoon: true,
    flag: 'https://snackat-cafe-test-12278090.dev.odoo.com/base/static/img/country_flags/om.png',
    countryKey: '+968',
    companyId: false,
  },
  {
    name: 'Qatar',
    arName: 'قطر',
    id: 186,
    currencyName: 'QAR',
    code: 'QA',
    arCurrencyName: 'ر.ق',
    commingSoon: false,
    flag: 'https://snackat-cafe-test-12278090.dev.odoo.com/base/static/img/country_flags/qa.png',
    countryKey: '+974',
    companyId: 106,
    center: { lat: 25.2854, lng: 51.531 },
  },
  {
    name: 'Saudi Arabia',
    arName: 'المملكة العربية السعودية',
    id: 192,
    currencyName: 'SAR',
    code: 'SA',
    arCurrencyName: 'SR',
    commingSoon: false,
    flag: 'https://snackat-cafe-test-12278090.dev.odoo.com/base/static/img/country_flags/sa.png',
    countryKey: '+966',
    companyId: 115,
    center: { lat: 24.7136, lng: 46.6753 },
  },
];

export const LIBRARIES: any = ['places', 'drawing', 'core', 'geometry'];

export const VALID_COUNTRIES = ['AE', 'QA', 'SA'];
export const DEFAULT_COUNTRY = 'AE';
export const DUMMY_URL =
  '/home?country=2&city=547&area=64&name=Ajman+City+Center&location=19';
