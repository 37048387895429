import * as React from 'react';
import LinearLoader from 'react-linear-loader';
import { useTranslation } from 'next-i18next';
import { FlagIcon } from '@heroicons/react/outline';

import Notification from 'src/modules/notifications/components/Notification';
import { isPushNotificationsSupported } from 'src/scripts/firebase';
import { useNotificationsContext } from '../providers/NotificationsProvider';
import AsyncList from '@SnackatCafe/snackat-ui/dist/AsyncList';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import { useGetNotifications } from '../api/queries';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { useMarkAllNotifications } from '../api/mutations';
import useMobile from 'src/hooks/useMobile/useMobile';

const NotificationsList: React.FC = () => {
  const { t } = useTranslation('common');

  const { isUserAllowed, openNotificationsPrompt } = useNotificationsContext();
  const { isAuthed } = useAuthContext();
  const isMobile = useMobile();

  const { mutate: markAll, isLoading } = useMarkAllNotifications();
  const { fetchNextPage, isFetching, hasNextPage, data } = useGetNotifications(
    null,
    {
      onData: data => {
        if (data?.data?.some(item => item?.isRead === false)) {
          markAll();
        }
      },
      enabled: isAuthed,
    }
  );

  return (
    <div>
      <AsyncList
        hasMore={hasNextPage}
        maxHeight={isMobile ? '80vh' : '450px'}
        onFetchNext={fetchNextPage}
        className="scrollbar min-w-[300px] divide-y divide-gray-200 overflow-y-auto"
      >
        {data?.map(item => (
          <Notification key={item.id} notification={item} />
        ))}

        {!hasNextPage ? (
          <div className="flex items-center justify-center gap-1 py-2 text-xs font-semibold text-gray-500">
            <FlagIcon width={14} height={14} />
            <div>{t('notifications.end')}</div>
          </div>
        ) : (
          <Button
            className="mt-2 w-full"
            size="sm"
            variant="gray"
            onClick={() => fetchNextPage()}
          >
            {t('notifications.load')}
          </Button>
        )}
      </AsyncList>

      {(isFetching || isLoading) && (
        <div className="absolute -bottom-0.5 left-0 right-0">
          <LinearLoader />
        </div>
      )}
      {isPushNotificationsSupported && !isUserAllowed && (
        <div>
          <Button
            size="sm"
            variant="dark"
            className="w-full rounded-t-none"
            onClick={openNotificationsPrompt}
          >
            {t('notifications.enableNotifications')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotificationsList;
