import { StringParam, useQueryParams } from 'use-query-params';

type UseParams = <Params>() => [p: Params, fn: (p: Params) => void];

const useParams: UseParams = () => {
  const result = useQueryParams({
    action: StringParam,
    id: StringParam,
  } as any);

  return result;
};

export default useParams;
