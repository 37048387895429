import * as React from 'react';
import { useLocalStorage } from 'react-use';
import * as rdd from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import {
  getMessagingToken,
  isPushNotificationsSupported,
  onMessageListener,
} from 'src/scripts/firebase';

import NotificationsEnablePrompt from '../components/NotificationsEnablePrompt';
import { useGetDevices, useGetNotificationsCount } from '../api/queries';
import { ApiAddDevicePayload } from '../api/types';
import {
  useRegisterDevice,
  useSubscribeUserToTopic,
  useUnSubscribeUserToTopic,
} from '../api/mutations';
import NotificationPopup from '../components/NotificationPopup';
import { Notification } from '../types';
import { camelize } from 'src/utils';
import { useNavigator } from 'src/hooks/useNavigator/useNavigator';
import useDialogActionHandlers from 'src/hooks/useDialogActionHandlers/useDialogActionHandlers';
import {
  MyBarcodeDialogAction,
  MyBarcodeDialogParams,
} from 'src/modules/myAccount/urls';
import { SingleActionParam } from 'src/types';
import useParams from 'src/hooks/useParams/useParams';

interface NotificationsContextValue {
  openNotificationsPrompt: () => void;
  isUserAllowed: boolean;
  unReadCount: number;
  notification: Notification;
  setNotify: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationsContext = React.createContext(
  null as NotificationsContextValue
);

const NotificationsProvider: React.FC = ({ children }) => {
  const [isPromptShownOnce, setIsPromptShownOnce] = useLocalStorage(
    'notifications-prompt-shown-once',
    false
  );
  const [isUserAllowed, setIsUserAllowed] = useLocalStorage(
    'notifications-allowed',
    false
  );

  const { isAuthed } = useAuthContext();
  const navigate = useNavigator();

  const { refetch: fetchDevices } = useGetDevices(null, { enabled: false });
  const registerDevice = useRegisterDevice();
  const { data: notificationsCountData } = useGetNotificationsCount(null, {
    enabled: isAuthed,
  });
  const { mutate: subscribeToTopic } = useSubscribeUserToTopic();
  const { mutate: unSubscribeToTopic } = useUnSubscribeUserToTopic();

  const [isPromptOpen, setIsPromptOpen] = React.useState(false);
  const [notification, setNotification] = React.useState<Notification>();
  const [notify, setNotify] = React.useState(false);

  const [params] = useParams<MyBarcodeDialogParams>();
  const [, close] = useDialogActionHandlers<
    MyBarcodeDialogAction,
    SingleActionParam
  >();

  const handleOrderRatingNotification = () => {
    const orderId = notification?.data?.orderId;
    const orderType = notification?.data?.notificationGroup;
    sessionStorage.setItem('orderId', orderId);
    sessionStorage.setItem('orderType', orderType);
    params.action === 'my-barcode' && close();
    !!orderId && setTimeout(() => navigate(`/myOrders`), 500);
  };

  onMessageListener()
    .then(payload => {
      console.info('MESSAGE: ', payload);
      const notificationPayload = camelize(payload);
      setNotification(notificationPayload);
      const isOrderRateNotf = !!notificationPayload?.data?.orderId;
      isOrderRateNotf ? handleOrderRatingNotification() : setNotify(true);
    })
    .catch(err => console.error('failed: ', err));

  const handleGetFcmToken = async () => {
    const token = await getMessagingToken();
    console.info('FCM Token: ', token);

    if (!isAuthed && token) {
      subscribeToTopic({ registrationIds: [token] });
    }
    if (isAuthed && token) {
      unSubscribeToTopic({ registrationIds: [token] });
    }

    if (token) {
      const { data } = isAuthed && (await fetchDevices());

      if (
        data &&
        !data?.results?.find(({ registrationId }) => registrationId === token)
      ) {
        console.info('New Device Detected');

        const newDevice: ApiAddDevicePayload = {
          deviceId: uuidv4().replaceAll('-', '').slice(0, 10),
          name: `${
            rdd.isMobile ? `${rdd.mobileModel}.${rdd.mobileVendor}` : ''
          }${rdd.osName}@${rdd.osVersion}-${rdd.browserName}@${
            rdd.browserVersion
          }`,
          registrationId: token,
          cloudMessageType: 'FCM',
          active: true,
        };

        isAuthed && registerDevice.mutate(newDevice);
      }
      setIsUserAllowed(true);
      setIsPromptOpen(false);
    }
  };

  React.useEffect(() => {
    let timer = null;

    if (isPushNotificationsSupported) {
      if (isUserAllowed) {
        handleGetFcmToken();
      } else if (!isPromptShownOnce) {
        timer = setTimeout(() => {
          setIsPromptOpen(true);
          setIsPromptShownOnce(true);
        }, 3000);
      }
    }

    return () => timer && clearTimeout(timer);
  }, [isAuthed]);

  const value: NotificationsContextValue = {
    isUserAllowed,
    openNotificationsPrompt: () => setIsPromptOpen(true),
    unReadCount: notificationsCountData?.counts,
    notification,
    setNotify,
  };

  return (
    <NotificationsContext.Provider value={value}>
      <>
        <NotificationsEnablePrompt
          isOpen={isPromptOpen}
          onAccept={handleGetFcmToken}
          onClose={() => setIsPromptOpen(false)}
        />
        <NotificationPopup show={notify} />
      </>

      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
export const useNotificationsContext = (): NotificationsContextValue =>
  React.useContext(NotificationsContext);
