import React from 'react';
import NextLink from 'next/link';

interface LinkProps {
  href: string;
}

const Link: React.FC<LinkProps> = props => {
  const { href, children } = props;

  return <NextLink href={href}>{children}</NextLink>;
};

Link.displayName = 'Link';

export default Link;
