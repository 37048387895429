import _camelCase from 'lodash/camelCase';

function camelize<T>(obj: T): any {
  if (Array.isArray(obj)) {
    return obj.map(v => camelize(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_camelCase(key)]: camelize(obj[key]),
      }),
      {}
    );
  }
  return obj;
}

export default camelize;
