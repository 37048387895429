import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface Product {
  name: string;
  id: number;
  arName: string;
  price: number;
  qty?: number;
  description?: string;
  arDescription?: string;
  brandName?: string;
  arBrandName?: string;
  brandImageUrl1920?: string;
  imageUrl1920: string | string[];
  imageUrl128: string | string[];
  amount: number;
  available?: boolean;
  scheduleForLater?: boolean;
  deliveryDays?: number;
}

export interface CartState {
  products: Product[];
}

const initialState: CartState = {
  products: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<Product>) => {
      if (state.products.some(item => item.id === action.payload.id)) {
        state.products = [
          ...state.products.map(item => {
            if (item.id === action.payload.id) {
              item.amount = action.payload.amount;
            }
            return item;
          }),
        ];
      } else {
        state.products = [...state.products, action.payload];
      }
    },
    increaseAmount: (state, action: PayloadAction<number>) => {
      state.products = [
        ...state.products.map(item => {
          if (item.id === action.payload) {
            item.amount++;
          }
          return item;
        }),
      ];
    },
    resetItemAmount: (
      state,
      action: PayloadAction<{ id: number; amount: number }>
    ) => {
      state.products = [
        ...state.products.map(item => {
          if (item.id === action.payload.id) {
            item.amount = action.payload.amount;
          }
          return item;
        }),
      ];
    },
    decreaseAmount: (state, action: PayloadAction<number>) => {
      state.products = [
        ...state.products.map(item => {
          if (item.id === action.payload && item.amount > 1) {
            item.amount--;
          }
          return item;
        }),
      ];
    },
    removeItem: (state, action: PayloadAction<number>) => {
      state.products = [
        ...state.products.filter(item => item.id !== action.payload),
      ];
    },
    clearBasket: state => {
      state.products = [];
    },
    updateCart: (state, action: PayloadAction<Product[]>) => {
      state.products = [...action.payload];
    },
  },
});

export const {
  addToCart,
  increaseAmount,
  decreaseAmount,
  removeItem,
  clearBasket,
  resetItemAmount,
  updateCart,
} = cartSlice.actions;

export default cartSlice.reducer;
