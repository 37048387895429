import * as React from 'react';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'react-use';

import {
  CurrentLocation,
  Location,
  UserLocation,
} from 'src/modules/address/types';
import { getUrlQueries } from 'src/utils/getUrlQueries';
import decodeArrayURL from 'src/utils/decodeArrayURL';
import { useUserContext } from 'src/modules/auth/providers/UserProvider';
import getUserCountry from 'src/utils/getUserCountry';
import { CountryCode } from 'src/types';
import { LatLng } from 'use-places-autocomplete';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { VALID_COUNTRIES } from 'src/modules/address/constants';

interface LocationContextValue {
  userLocation: UserLocation;
  latestLocation: Location;
  setLatestLocation: React.Dispatch<React.SetStateAction<Location>>;
  clearLatestLocation: () => void;
  userCountry: CountryCode;
  setUserCountry: React.Dispatch<React.SetStateAction<CountryCode>>;
  areaPrice: number;
  setAreaPrice: React.Dispatch<React.SetStateAction<number>>;
  mapPin: LatLng;
  setMapPin: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  currentLocation: CurrentLocation;
  setCurrentLocation: React.Dispatch<React.SetStateAction<CurrentLocation>>;
  setAddressId: React.Dispatch<React.SetStateAction<string>>;
  addressId: string;
}

const LocationContext = React.createContext(null as LocationContextValue);

const LocationProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const [latestLocation, setLatestLocation, clearLatestLocation] =
    useLocalStorage<Location>('map-location');
  const [userCountry, setUserCountry] = useLocalStorage<CountryCode>(
    'country',
    'AE'
  );
  const [areaPrice, setAreaPrice] = useLocalStorage<number>('area-price');
  const [mapPin, setMapPin] = useLocalStorage<LatLng>('map-pin');
  const [currentLocation, setCurrentLocation] =
    useLocalStorage<CurrentLocation>('current-location');
  const [addressId, setAddressId] = useLocalStorage<string>(
    'address-id',
    'LATEST-MAP-PIN'
  );

  const { user } = useUserContext();
  const { isAuthed } = useAuthContext();
  const queries = getUrlQueries(router.asPath);
  const isLocationRoute = router.pathname === `/`;
  const isHomeRoute = router.pathname === `/home`;
  const countryCode = router.query.countryCode as CountryCode;

  const {
    location,
    country: countryId,
    city: cityId,
    area: areaId,
  } = queries ? queries : router.query;

  const userLocation = queries && {
    area: areaId,
    city: cityId,
    country: parseInt(countryId),
    locationIds: decodeArrayURL(location),
  };

  const isCurrentLocEqLatest = // check if current location equal latest location
    mapPin?.lat?.toFixed(6) === latestLocation?.mapPin?.lat?.toFixed(6) &&
    mapPin?.lng?.toFixed(6) === latestLocation?.mapPin?.lng?.toFixed(6);

  React.useEffect(() => {
    // if user choose the latest location set latest location to match current location
    if (
      addressId === 'LATEST-MAP-PIN' &&
      isAuthed &&
      !isCurrentLocEqLatest &&
      isHomeRoute
    ) {
      setLatestLocation({
        area: currentLocation?.area,
        city: currentLocation?.city,
        branchId: userLocation?.locationIds,
        mapPin,
        countryId: userLocation?.country,
      });
    }
  }, [addressId, isAuthed, isCurrentLocEqLatest, isHomeRoute]);

  React.useEffect(() => {
    if (isLocationRoute && latestLocation?.city) {
      router.push(
        `/home?country=${latestLocation.countryId}&city=${latestLocation.city.name}&area=${latestLocation.area.name}&location=${latestLocation.branchId}`
      );
    }
  }, [latestLocation?.city, isLocationRoute]);

  React.useEffect(() => {
    if (!isLocationRoute && !mapPin?.lat && !latestLocation?.city) {
      router.push(`/${userCountry}`);
    }
  }, [mapPin?.lat, isLocationRoute, latestLocation?.city]);

  React.useEffect(() => {
    if (user?.countryId) {
      setUserCountry(getUserCountry(parseInt(user?.countryId)));
    }
    if (countryCode && VALID_COUNTRIES.includes(countryCode)) {
      setUserCountry(countryCode);
    } else {
      !userCountry && setUserCountry('AE');
    }
  }, [user?.countryId, countryCode]);

  const value = {
    userLocation,
    latestLocation,
    setLatestLocation,
    clearLatestLocation,
    userCountry,
    setUserCountry,
    areaPrice,
    setAreaPrice,
    mapPin,
    setMapPin,
    currentLocation,
    setCurrentLocation,
    addressId,
    setAddressId,
  };

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
export const useLocationContext = (): LocationContextValue =>
  React.useContext(LocationContext);
