import React from 'react';
import { useRouter } from 'next/router';

interface SearchContextValue {
  searchKey: string;
  setSearchKey: React.Dispatch<React.SetStateAction<string>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchContext = React.createContext({} as SearchContextValue);

const SearchProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const [searchKey, setSearchKey] = React.useState<string>('');
  const [query, setQuery] = React.useState<string>('');
  const isSearchRoute = router.pathname === '/search';

  React.useEffect(() => {
    if (!isSearchRoute) {
      setQuery('');
    }
  }, [isSearchRoute]);

  const value = {
    searchKey,
    setSearchKey,
    query,
    setQuery,
  };
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export default SearchProvider;
export const useSearchContext = (): SearchContextValue =>
  React.useContext(SearchContext);
