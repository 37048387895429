import * as React from 'react';
import { GiftIcon } from '@heroicons/react/outline';
import Button from '@SnackatCafe/snackat-ui/dist/Button';

import useNavigator from 'src/hooks/useNavigator';
import { useAuthContext } from 'src/modules/auth/providers/AuthProvider';
import { useGetUserLoyaltyPoints } from 'src/modules/loyality/api/queries';
interface GiftButtonProps {
  hide: boolean;
  light?: boolean;
}

const GiftButton: React.FC<GiftButtonProps> = ({ hide, light }) => {
  const navigate = useNavigator();
  const { isAuthed } = useAuthContext();

  const { data: userPoints } = useGetUserLoyaltyPoints(
    {},
    { enabled: isAuthed }
  );

  return (
    <div className={`relative ${hide && 'hidden'}`} data-cy="cy-gift-btn">
      <Button
        leftIcon={GiftIcon}
        iconProps={{ size: 25 }}
        className={`mt-2 p-0 text-primary-500`}
        onClick={() => {
          navigate('/reward');
        }}
        variant="text"
      />
      <div
        className={`  absolute flex cursor-pointer ${
          isAuthed ? '-top-3 -left-4 h-6 w-6' : '-top-1 -left-2 h-4 w-4'
        }  items-center justify-center rounded-full ${
          light ? 'bg-white text-black' : 'bg-black text-white'
        } `}
        onClick={() => {
          navigate('/reward');
        }}
      >
        <p className="text-[0.6rem] ltr:translate-y-[1px]">
          {isAuthed && userPoints?.loyaltyPoints}
        </p>
      </div>
    </div>
  );
};

export default GiftButton;
