export const getUrlQueries = (URL: string) => {
  const queries = URL.split('?')[1]
    ?.split('&')
    ?.map(item => item.split('='))
    ?.map(i => ({ [i[0]]: decodeURI(i[1]).replace(/\+/g, ' ') }));

  const queriesObject = queries && Object.assign({}, ...queries);

  return queriesObject;
};
