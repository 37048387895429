import Image from '@SnackatCafe/snackat-ui/dist/Image';
import * as React from 'react';
import useMobile from 'src/hooks/useMobile/useMobile';
import { primaryColor } from 'src/styles';
import { useNotificationsContext } from '../providers/NotificationsProvider';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import { XIcon } from '@heroicons/react/outline';
import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import ReactTimeAgo from 'react-time-ago';
import { parseISO } from 'date-fns';
import { useFilterContext } from 'src/modules/app/providers/FilterProvider';
import { useNavigator } from 'src/hooks/useNavigator/useNavigator';

interface NotificationPopupProps {
  show: boolean;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({ show }) => {
  const isMobile = useMobile();
  const navigate = useNavigator();
  const { language } = useTranslationContext();
  const { notification, setNotify } = useNotificationsContext();
  const { setCategoryId, setBrandId } = useFilterContext();

  const title = notification?.notification?.title;
  const body = notification?.notification?.body;

  const image = notification?.data?.imageUrl1920;

  const handleNotificationClick = () => {
    const page = notification?.data?.page;
    const categoryIds =
      notification && JSON.parse(notification.data?.categoryIds)?.at(0);
    const brandIds = notification && JSON.parse(notification?.data?.brandIds);
    const productIds =
      notification && JSON.parse(notification?.data?.productList)?.at(0);
    const rewardLineIds =
      notification && JSON.parse(notification?.data?.rewardLineIds)?.at(0);
    categoryIds ? setCategoryId(categoryIds) : setCategoryId(0);
    !!brandIds.at(0) && setBrandId(brandIds);
    !!productIds && navigate(`products/${productIds}`);
    (!!brandIds?.at(0) || !!categoryIds) && navigate('/products');
    page !== 'false' && navigate(`/reward`);
    !!rewardLineIds && navigate(`gift/${rewardLineIds}`);
    setNotify(false);
  };

  return (
    <div
      className={`fixed top-8 z-20 w-[280px]  transform cursor-pointer rounded-md bg-gray-100 p-4 shadow-md duration-200 ltr:left-0 rtl:right-0 md:w-[300px] ${
        show
          ? ' ltr:translate-x-4 rtl:-translate-x-4'
          : 'ltr:-translate-x-[350px] rtl:translate-x-[350px]'
      }`}
      onClick={handleNotificationClick}
    >
      <Button
        leftIcon={XIcon}
        variant="text"
        className="absolute top-2 m-0 p-0 ltr:right-2 rtl:left-2
        "
        onClick={e => {
          e.stopPropagation();
          setNotify(false);
        }}
      />
      <div
        className={`absolute top-2 mt-1.5 h-1.5 w-1.5 rounded-full bg-primary-500 transition-all duration-200 ltr:left-2 ltr:mr-2 rtl:right-2
            rtl:ml-2`}
      />
      <div className="flex w-full items-center justify-between">
        <div className="flex w-10/12 items-center gap-x-4">
          {image && (
            <Image
              src={image}
              alt={title}
              width={isMobile ? '50px' : '75px'}
              height={isMobile ? '50px' : '75px'}
              cover
              className="rounded-md"
              loaderColor={primaryColor}
            />
          )}
          <div>
            <div className="max-w-36 md:max-w-44 font-semibold first-letter:capitalize md:text-base">
              {title}
            </div>
            <div>{body}</div>
          </div>
        </div>
      </div>
      {notification && (
        <div className="text-xxs absolute bottom-2.5 z-10 text-gray-400  ltr:right-2 rtl:left-2 ">
          <ReactTimeAgo
            date={parseISO(notification?.data?.notificationSentDatetime)}
            locale={language}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
