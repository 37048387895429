import * as React from 'react';
import LinearLoader from 'react-linear-loader';

import LogoIcon from 'src/components/LogoIcon';

const LoadingLayout: React.FC = () => {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <LogoIcon size={150} />
      <div className="my-2 w-44">
        <LinearLoader />
      </div>
    </div>
  );
};

export default LoadingLayout;
