import React from 'react';
import { SortFilter } from 'src/modules/allProducts/types';

interface FilterContextValue {
  brandId: number[];
  setBrandId: React.Dispatch<React.SetStateAction<number[]>>;
  categoryId: number;
  setCategoryId: React.Dispatch<React.SetStateAction<number>>;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  resetFilters: () => void;
}

const FilterContext = React.createContext(null as FilterContextValue);

const FilterProvider: React.FC = ({ children }) => {
  const [brandId, setBrandId] = React.useState<number[]>([]);
  const [categoryId, setCategoryId] = React.useState<number>(0);
  const [filter, setFilter] = React.useState<SortFilter>(
    'website_sequence%2Basc'
  );

  const resetFilters = () => {
    setBrandId([]);
    setCategoryId(0);
    setFilter('website_sequence%2Basc');
  };

  const value = {
    brandId,
    setBrandId,
    categoryId,
    setCategoryId,
    filter,
    setFilter,
    resetFilters,
  };
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export default FilterProvider;
export const useFilterContext = (): FilterContextValue =>
  React.useContext(FilterContext);
