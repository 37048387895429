import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Transition } from '@headlessui/react';
import { WifiIcon } from '@heroicons/react/outline';
import { captureException } from '@sentry/nextjs';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import Alert from '@SnackatCafe/snackat-ui/dist/Alert';

import { useTranslation } from 'next-i18next';
import { API_VALIDATION_ERRORS } from './api/constants';
import apiErrorMessage from 'src/utils/apiErrorMessage';
import useAlert from 'src/hooks/useAlert';
import ErrorFallback from './views/ErrorFallback';
import { ApiError } from 'src/api/types';

const Toast = dynamic(() => import('../app/components/Toast'));

interface ErrorBoundaryContextValue {
  onQueryError: (repeatQuery: () => Promise<any>, error: ApiError) => void;
  onMutationError: (error: ApiError, options?: { suppress: boolean }) => string;
}

const ErrorBoundaryContext = React.createContext(
  null as ErrorBoundaryContextValue
);

const ErrorBoundaryProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('common');
  const { pathname } = useRouter();

  const [alert, setAlert] = useAlert();

  const [repeatQuery, setRepeatQuery] = React.useState(null);
  const [queryError, setQueryError] = React.useState(null);

  const onMutationError = (
    error: ApiError,
    { suppress } = { suppress: false }
  ) => {
    let message = apiErrorMessage(error);

    if (API_VALIDATION_ERRORS[message]) {
      message = t(`errors.${API_VALIDATION_ERRORS[message]}`);
    } else {
      if (message) {
        const messageError = new Error(message);
        messageError.name = 'API Request Error';
        captureException(messageError, {
          captureContext: scope =>
            scope.setExtras({
              status: error.response.status,
              response: error.response.data,
              message: error.message,
              URL: error.response.request.responseURL,
              request: error.response.config.data,
            }),
        });
      } else {
        captureException(error, {
          captureContext: scope =>
            scope.setExtras({
              status: error.response.status,
              response: error.response.data,
              message: error.message,
              URL: error.response.request.responseURL,
              request: error.response.config.data,
            }),
        });
      }

      message = message || t('errors.general');
    }

    if (!suppress) {
      setAlert({ status: 'error', message });
    }

    return message;
  };

  const onQueryError = (
    repeatQuery: () => Promise<any>,
    error: Record<string, any>
  ): void => {
    setRepeatQuery(() => repeatQuery);
    setQueryError(error.message);

    if (error.message !== 'Network Error') {
      captureException(error, {
        captureContext: scope =>
          scope.setExtras({
            status: error.response.status,
            response: error.response.data,
            message: error.message,
            URL: error.response.request.responseURL,
            request: error.response.config.data,
          }),
      });
    }
  };

  const handleRetry = () => {
    repeatQuery();
    setRepeatQuery(null);
    setQueryError(null);
  };

  const handleClientError = error => {
    captureException(error);
  };

  React.useEffect(() => {
    queryError && setQueryError(null);
  }, [pathname]);

  const isOffline = queryError === 'Network Error';

  const value = {
    onQueryError,
    onMutationError,
  };

  return (
    <ErrorBoundaryContext.Provider value={value}>
      <ErrorBoundary
        FallbackComponent={() => <ErrorFallback code={500} />}
        onError={handleClientError}
      >
        {children}
        <Transition appear show={!!queryError} as={React.Fragment}>
          <div className="fixed bottom-16 right-4 z-20">
            <Transition.Child
              as="div"
              enter="transition ease-out duration-500"
              enterFrom="transform translate-x-full"
              enterTo="transform translate-x-0"
              leave="transition ease-in duration-200"
              leaveFrom="transform translate-x-0"
              leaveTo="transform translate-x-full"
            >
              <Alert
                show
                title={
                  isOffline
                    ? t('errors.offline.title')
                    : t('errors.unknown.title')
                }
                status="error"
                className="max-w-xs text-sm text-gray-500"
                icon={isOffline ? WifiIcon : undefined}
              >
                <div>
                  <div className="mb-2 font-medium">
                    {isOffline
                      ? t('errors.offline.details')
                      : t('errors.unknown.details')}
                  </div>

                  <div className="flex items-center rtl:text-right">
                    <Button
                      size="sm"
                      color="primary"
                      rounded="full"
                      variant="ghost"
                      onClick={handleRetry}
                    >
                      {t('buttons.tryAgain')}
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      rounded="full"
                      variant="muted"
                      onClick={() => setQueryError(null)}
                      className="mx-2"
                    >
                      {t('buttons.close')}
                    </Button>
                  </div>
                </div>
              </Alert>
            </Transition.Child>
          </div>
        </Transition>

        {alert.message && <Toast alert={alert} />}
      </ErrorBoundary>
    </ErrorBoundaryContext.Provider>
  );
};

export default ErrorBoundaryProvider;
export const useErrorBoundaryContext = (): ErrorBoundaryContextValue =>
  React.useContext(ErrorBoundaryContext);
