import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import Image from '@SnackatCafe/snackat-ui/dist/Image';
import SearchInput from '@SnackatCafe/snackat-ui/dist/SearchInput';
import { useRouter } from 'next/router';

import useDialogActionHandlers from 'src/hooks/useDialogActionHandlers';
import { ProductFilterSelectDialogAction } from 'src/modules/allProducts/urls';
import { useSearchContext } from 'src/modules/app/providers/SearchProvider';
import { SingleActionParam } from 'src/types';
import { useTranslation } from 'next-i18next';
import { primaryColor } from 'src/styles';
import useNavigator from 'src/hooks/useNavigator';

const SearchFilter: React.FC = () => {
  const router = useRouter();
  const navigate = useNavigator();
  const { t } = useTranslation('landing');
  const { query, setQuery } = useSearchContext();
  const [open] = useDialogActionHandlers<
    ProductFilterSelectDialogAction,
    SingleActionParam
  >();
  const { searchKey } = useSearchContext();

  const isProductsRoute = router.pathname === '/products';

  React.useEffect(() => {
    if (searchKey) {
      setQuery(searchKey);
    }
  }, [searchKey]);

  return (
    <>
      <div
        data-cy="cy-search-input"
        className="my-2 flex w-full rounded-full border bg-white"
        onClick={() => {
          if (router.pathname !== '/search') {
            navigate('/search');
          }
        }}
      >
        <SearchInput
          value={query}
          onChange={val => {
            setQuery(val);
          }}
          className="border-none px-4 py-1"
          icon={SearchIcon}
          placeholder={t('placeholders.search')}
        />
        {isProductsRoute && (
          <div
            className="m-auto h-fit w-fit cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              open('sort-by');
            }}
          >
            <Image
              src={'/images/filterIcon.svg'}
              width="50"
              height={'50'}
              alt="filter icon"
              loaderColor={primaryColor}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchFilter;
