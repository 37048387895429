import * as React from 'react';
import { HeroIcon } from '@SnackatCafe/snackat-ui';
import IconWrapper from '@SnackatCafe/snackat-ui/dist/IconWrapper';
import { useRouter } from 'next/router';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  icon?: HeroIcon;
  iconSize?: number;
  className?: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  href,
  children,
  icon,
  className,
  iconSize,
  ...props
}) => {
  const { pathname, push, back, query } = useRouter();
  const isActive = pathname === href;

  if (isActive) {
    (props as HTMLElement).className += ' text-primary-500';
  }

  const navigateToURL = () => {
    if (
      ['/myAccount/language', '/myAccount/aboutApp'].includes(pathname) &&
      href === '/myAccount'
    ) {
      back();
    } else {
      push({ pathname: href, query });
    }
  };

  return (
    <div onClick={navigateToURL} data-cy="cy-navbar-link">
      <div className={`${!!className && className} flex items-center gap-x-1`}>
        {icon && (
          <IconWrapper
            icon={icon}
            size={iconSize ? iconSize : 15}
            className={`mb-1 ${isActive && 'text-primary-500'}`}
          />
        )}
        <div {...props} data-cy="cy-active-navbar-link">
          {children}
        </div>
      </div>
    </div>
  );
};

export default NavLink;
